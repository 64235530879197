import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../../../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-frontend-tabulka-select',
	templateUrl: './formular-frontend-tabulka-select.component.html',
	styleUrls: ['./formular-frontend-tabulka-select.component.scss']
})
export class FormularFrontendTabulkaSelectComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Array of input items
	 */
	@Input() polozky: any;

	/**
	 * Defines if items will be sorted
	 */
	@Input() seraditPolozky = false;

	/**
	 * Defines not selected label
	 */
	@Input() nezvolenyPopisek = 'formularSelect.nezvoleno';

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
