import {Component} from '@angular/core';
import {AutentizaceService} from './service/bezpecnost/autentizace.service';
import {ScrollService} from './service/scroll.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {

	constructor(
		private autentizaceService: AutentizaceService,
		private scrollService: ScrollService
	) {
	}

	ngOnInit(): void {
		if (this.autentizaceService.jeUzivatelPrihlasen()) {
			this.autentizaceService.aktualizovatInformaceOUzivateliVLokalnimUlozisti();
		}
		this.scrollService.registrovatScroll();
	}
}
