import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from './service/bezpecnost/auth-guard.service';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./layout/domu/domu.module').then(m => m.DomuModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: false
		}
	},
	{
		path: 'doregistrace-uzivatele',
		loadChildren: () => import('./layout/doregistrace-uzivatele/doregistrace-uzivatele.module').then(m => m.DoregistraceUzivateleModule),
		data: {
			kontrolovatOpravneni: false
		}
	},
	{
		path: 'registrace',
		loadChildren: () => import('./layout/registrace/registrace.module').then(m => m.RegistraceModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: false
		}
	},
	{
		path: 'muj-subjekt',
		loadChildren: () => import('./layout/muj-subjekt/muj-subjekt.module').then(m => m.MujSubjektModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'muj-ucet',
		loadChildren: () => import('./layout/muj-ucet/muj-ucet.module').then(m => m.MujUcetModule),
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'sprava-administratoru-a-uzivatelu',
		loadChildren: () => import('./layout/sprava-administratoru-a-uzivatelu/sprava-administratoru-a-uzivatelu.module').then(m => m.SpravaAdministratoruAUzivateluModule),
		data: {
			kontrolovatOpravneni: true
		}
	},
	{
		path: 'testovaci-stranka',
		loadChildren: () => import('./layout/testovaci-stranka/testovaci-stranka.module').then(m => m.TestovaciStrankaModule),
		canActivate: [AuthGuardService],
		data: {
			kontrolovatOpravneni: true
		}
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		scrollPositionRestoration: 'top'
	})],
	exports: [RouterModule],
	providers: [AuthGuardService]
})
export class AppRoutingModule {
}
