import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeAutomatickehoDoplnovani {

	private static readonly maxColumnWidthFr = 10;
	private static readonly minValueLength = 1;

	public static pripravitHodnotySloupcuGridSablony(dataAttributeNames: string[], items: string[]): string {
		let gridTemplateColumnsValue: string = '';

		for (const columnWidth of this.pripravitSirkySloupcu(this.pripravitMaxDelku(dataAttributeNames, items))) {
			gridTemplateColumnsValue = NastrojeObecne.jePrazdnaHodnota(gridTemplateColumnsValue) ? NastrojeObecne.formatovatText('{0}fr', columnWidth) : NastrojeObecne.formatovatText('{0} {1}fr', gridTemplateColumnsValue, columnWidth);
		}

		return gridTemplateColumnsValue;
	}

	public static pripravitHodnotuAtributu(data: any, key: string): any {
		let value: any = data;

		for (const item of key.split('.')) {
			value = value[item];
		}

		return value;
	}

	private static pripravitMaxDelku(dataAttributeNames: string[], items: string[]): number[] {
		const maxLengths = new Map<string, number>();

		for (const item of items) {
			dataAttributeNames.forEach(key => {
				if (!NastrojeObecne.jePrazdnaHodnota(this.pripravitHodnotuAtributu(item, key))) {
					maxLengths.set(key, Math.max(this.pripravitHodnotuAtributu(item, key).length, !NastrojeObecne.jePrazdnaHodnota(maxLengths.get(key)) ? maxLengths.get(key) : this.minValueLength));
				} else {
					maxLengths.set(key, this.minValueLength);
				}
			});
		}

		return Array.from(maxLengths.values());
	}

	private static pripravitSirkySloupcu(maxLengths: number[]): number[] {
		const minOfMaxLengths = Math.min(...maxLengths);
		const columnWidths: number[] = [];

		for (const maxLength of maxLengths) {
			columnWidths.push(Math.min((maxLength / minOfMaxLengths), this.maxColumnWidthFr));
		}

		return columnWidths;
	}
}
