import {TooltipComponent} from '@angular/material/tooltip';

export class NastrojeTooltip {

	public static povolitHtmlObsah(): void {
		Object.defineProperty(TooltipComponent.prototype, 'message', {
			set(hodnota: string) {
				const element = document.querySelectorAll('.mat-tooltip');

				if (element) {
					element[element.length - 1].innerHTML = hodnota;
				}
			},
			configurable: true
		});
	}
}
