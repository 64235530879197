import czJson from '../../assets/lokalizace/cz.json';
import enJson from '../../assets/lokalizace/en.json';
import {NastrojeObecne} from './nastroje/nastroje-obecne';
import {NastrojeLokalnihoUloziste} from './nastroje/nastroje-lokalniho-uloziste';
import {NastrojeLokalizace} from './nastroje/nastroje-lokalizace';
import {TypLokalizace} from '../data/general/enum/typ-lokalizace.enum';

export class Lokalizace {

	private static readonly data = Lokalizace.pripravitDataLokalizace(NastrojeLokalnihoUloziste.pripravitHodnotu(NastrojeLokalizace.NAZEV_KLICE_HODNOTY_LOKALIZACE_V_LOKALNIM_ULOZISTI) as TypLokalizace);

	public static pripravitText(klic: string): string {
		return this.data && this.data[klic] ? this.data[klic] : klic;
	}

	public static formatovatText(klic: string, ...parametry: any[]): string {
		return NastrojeObecne.formatovatText(Lokalizace.pripravitText(klic), ...parametry);
	}

	public static prevestKlicNaLowerCamelCase(klic: string) {
		return klic.replace(/(^\w|[.]\w)/g, m => m.toLowerCase());
	}

	public static pripravitDataLokalizace(typ: TypLokalizace): any {
		switch (typ) {
			case TypLokalizace.CZ:
				return (czJson as any);
			case TypLokalizace.EN:
				return (enJson as any);
			default:
				return (czJson as any);
		}
	}
}
