import {SortDirection} from '@angular/material/sort';
import {SmerRazeniEnum} from '../../data/table/enum/smer-razeni.enum';
import {KonfiguraceSloupec} from '../../data/table/table-configuration/konfigurace-sloupec';
import {DataType} from '../enum/data-type.enum';
import {NastrojeCisel} from './nastroje-cisel';
import {NastrojeDataACasu} from './nastroje-data-a-casu';
import {NastrojeObecne} from './nastroje-obecne';
import {NastrojeObecneSoubory} from './nastroje-obecne-soubory';

export class NastrojeRazeniTabulky {

	public static pripravitRazeniJakoSortDirection(razeni: SmerRazeniEnum): SortDirection {
		switch (razeni) {
			case SmerRazeniEnum.VZESTUPNE:
				return 'asc';
			case SmerRazeniEnum.SESTUPNE:
				return 'desc';
			default:
				return null;
		}
	}

	public static pripravitSortDirectionJakoRazeni(razeni: SortDirection): SmerRazeniEnum {
		switch (razeni) {
			case 'asc':
				return SmerRazeniEnum.VZESTUPNE;
			case 'desc':
				return SmerRazeniEnum.SESTUPNE;
			default:
				return null;
		}
	}

	public static porovnatHodnotyPodleDatovehuTypuSloupceAAtributu(prvniHodnota: any, druhaHodnota: any, sloupec: KonfiguraceSloupec, atributKSerazeni: string, raditVzestupne: boolean): number {
		switch (sloupec.format.typ) {
			case DataType.DATE:
				const dateFormat = sloupec.format.formatDatumu ? sloupec.format.formatDatumu.toString() : NastrojeDataACasu.FORMAT_DATA;

				return NastrojeRazeniTabulky.porovnatDatumy(NastrojeDataACasu.prevestTextNaDatum(prvniHodnota[atributKSerazeni], dateFormat), NastrojeDataACasu.prevestTextNaDatum(druhaHodnota[atributKSerazeni], dateFormat), raditVzestupne);
			case DataType.NUMBER:
				return NastrojeRazeniTabulky.porovnatCislo(NastrojeCisel.prevestNaCislo(prvniHodnota[atributKSerazeni]), NastrojeCisel.prevestNaCislo(druhaHodnota[atributKSerazeni]), raditVzestupne);
			case DataType.FILE_SIZE:
				return NastrojeRazeniTabulky.porovnatCislo(NastrojeObecneSoubory.pripravitTextVelikostiSouboruJakoCislo(prvniHodnota[atributKSerazeni]), NastrojeObecneSoubory.pripravitTextVelikostiSouboruJakoCislo(druhaHodnota[atributKSerazeni]), raditVzestupne);
			default:
				return NastrojeRazeniTabulky.porovnatRetezce(NastrojeObecne.jePrazdnaHodnota(prvniHodnota[atributKSerazeni]) ? '' : prvniHodnota[atributKSerazeni], NastrojeObecne.jePrazdnaHodnota(druhaHodnota[atributKSerazeni]) ? '' : druhaHodnota[atributKSerazeni], raditVzestupne);
		}
	}

	public static porovnatCislo(prvniHodnota: number, druhaHodnota: number, raditVzestupne: boolean): number {
		const isFirstValueEmptyOrNotNumber = NastrojeObecne.jePrazdnaHodnota(prvniHodnota) || isNaN(prvniHodnota.valueOf());
		const isSecondValueEmptyOrNotNumber = NastrojeObecne.jePrazdnaHodnota(druhaHodnota) || isNaN(druhaHodnota.valueOf());

		if (!isFirstValueEmptyOrNotNumber && !isSecondValueEmptyOrNotNumber) {
			return (prvniHodnota < druhaHodnota ? -1 : 1) * (raditVzestupne ? 1 : -1);
		}

		return this.porovnatPrazdneNeboNevalidniHodnoty(isFirstValueEmptyOrNotNumber, isSecondValueEmptyOrNotNumber, raditVzestupne);
	}

	public static porovnatRetezce(prvniHodnota: string, druhaHodnota: string, raditVzestupne = true): number {
		const isFirstValueEmpty = NastrojeObecne.jePrazdnaHodnota(prvniHodnota);
		const isSecondValueEmpty = NastrojeObecne.jePrazdnaHodnota(druhaHodnota);

		if (!isFirstValueEmpty && !isSecondValueEmpty) {
			return prvniHodnota.toString().localeCompare(druhaHodnota.toString(), 'cs-CZ') * (raditVzestupne ? 1 : -1);
		}

		return this.porovnatPrazdneNeboNevalidniHodnoty(isFirstValueEmpty, isSecondValueEmpty, raditVzestupne);
	}

	public static porovnatDatumy(prvniHodnota: Date, druhaHodnota: Date, raditVzestupne: boolean): number {
		const isFirstValueEmptyOrNotDate = NastrojeObecne.jePrazdnaHodnota(prvniHodnota) || !(prvniHodnota instanceof Date) || isNaN(prvniHodnota.valueOf());
		const isSecondValueEmptyOrNotDate = NastrojeObecne.jePrazdnaHodnota(druhaHodnota) || !(druhaHodnota instanceof Date) || isNaN(druhaHodnota.valueOf());

		if (!isFirstValueEmptyOrNotDate && !isSecondValueEmptyOrNotDate) {
			return (prvniHodnota.valueOf() < druhaHodnota.valueOf() ? -1 : 1) * (raditVzestupne ? 1 : -1);
		}

		return this.porovnatPrazdneNeboNevalidniHodnoty(isFirstValueEmptyOrNotDate, isSecondValueEmptyOrNotDate, raditVzestupne);
	}

	private static porovnatPrazdneNeboNevalidniHodnoty(jePrvniHodnotaPrazdnaNeboNevalidni: boolean, jeDruhaHodnotaPrazdnaNeboNevalidni: boolean, raditVzestupne: boolean): number {
		if (jePrvniHodnotaPrazdnaNeboNevalidni && !jeDruhaHodnotaPrazdnaNeboNevalidni) {
			return raditVzestupne ? 1 : -1;
		} else if (!jePrvniHodnotaPrazdnaNeboNevalidni && jeDruhaHodnotaPrazdnaNeboNevalidni) {
			return raditVzestupne ? -1 : 1;
		} else {
			return 0;
		}
	}
}
