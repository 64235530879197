import {Component, HostBinding, HostListener, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormularMultipleAutocompleteService} from '../../../../../service/formular-multiple-autocomplete.service';
import {NastrojeAutomatickehoDoplnovani} from '../../../../nastroje/nastroje-automatickeho-doplnovani';
import {NastrojeFormularu} from '../../../../nastroje/nastroje-formularu';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {NastrojeRazeniTabulky} from '../../../../nastroje/nastroje-razeni-tabulky';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-multiple-autocomplete',
	templateUrl: './formular-multiple-autocomplete.component.html',
	styleUrls: ['./formular-multiple-autocomplete.component.scss']
})
export class FormularMultipleAutocompleteComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	@ViewChild(MatAutocompleteTrigger, {read: MatAutocompleteTrigger}) inputAutocomplete: MatAutocompleteTrigger;

	/**
	 * Minimal number of characters to show autocomplete
	 */
	@Input() minimalniPocetZnaku = 0;

	/**
	 * Data structure object consisting of configuration and display data
	 */
	@Input() datovaStruktura: { konfiguraceValidatoru: any[], data: any[], guid: string };

	/**
	 * Name of data object attribute that will be used by this component
	 */
	@Input() nazevDataAtributu: string;

	/**
	 * CSS autocomplete grid columns style.
	 */
	@Input() gridTemplateColumns: string;

	private readonly id = NastrojeObecne.vygenerovatNahodnyRetezec();
	private readonly maximalniPocetZobrazenychPolozek = 10;

	public vyfiltrovanePolozky: Observable<string[]>;
	public nazvyDataAtributu: string[];

	private currentValueChangeOfControlIsTriggeredBySelectionChange = false;

	@HostBinding('attr.id') get getId(): any {
		return this.id;
	}

	@HostListener('document:keyup.escape', ['$event']) onKeyup(event: any): void {
		if (NastrojeFormularu.jePrvekSoucastiTohotoFormulare(event.target, this.id)) {
			this.smazatVsechnyMultipleAutocompleteHodnoty();
			this.inputAutocomplete.openPanel();
		}
	}

	constructor(private formMultipleAutocompleteService: FormularMultipleAutocompleteService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.inicializovatGuid();
		this.inicializujJmenaDatovychAtributu();
		this.registrovatChangeListenery();
		this.registrovatEventListenery();

		if (this.jePrvekSoucastiDataStrukturySeSkrytymiPrvky()) {
			this.registrujChangeListenerForSettingHiddenControlValue();
		}
	}

	public emitOptionSelectedEvent(event: any, data: any): void {
		this.currentValueChangeOfControlIsTriggeredBySelectionChange = true;

		if (event.isUserInput) {
			this.formMultipleAutocompleteService.emitOptionSelectedEvent(this.datovaStruktura.guid, this.nazevDataAtributu, data);
		}
	}

	public pripravHodnotuAtributu(data: any, key: string): any {
		return NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(data, key);
	}

	private inicializovatGuid(): void {
		if (NastrojeObecne.jePrazdnaHodnota(this.datovaStruktura.guid)) {
			this.datovaStruktura.guid = NastrojeObecne.vygenerovatNahodnyRetezec();
		}
	}

	private inicializujJmenaDatovychAtributu(): void {
		this.nazvyDataAtributu = this.datovaStruktura.konfiguraceValidatoru.filter(configuration => !configuration.hideFormControl).map(configuration => configuration.dataAttributeName);
	}

	private jePrvekSoucastiDataStrukturySeSkrytymiPrvky(): boolean {
		return !!this.datovaStruktura.konfiguraceValidatoru.find(configuration => configuration.hideFormControl === true);
	}

	private registrovatEventListenery(): void {
		this.formMultipleAutocompleteService.optionSelectedEvent.subscribe(value => {
			if (value.guid === this.datovaStruktura.guid) {
				this.formController.setValue(NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(value.data, this.nazevDataAtributu));
				this.formController.markAsTouched();
			}
		});
	}

	private registrovatChangeListenery(): void {
		this.vyfiltrovanePolozky = this.formController.valueChanges.pipe(map(item => {
			if (this.minimalniPocetZnaku > 0 && (NastrojeObecne.jePrazdnaHodnota(item) || item.length < this.minimalniPocetZnaku)) {
				return [];
			}

			const filteredItems = item ? this.filter(item).slice(0, this.maximalniPocetZobrazenychPolozek) : this.datovaStruktura.data.slice(0, this.maximalniPocetZobrazenychPolozek);

			filteredItems.sort((firstItem: any, secondItem: any) => NastrojeRazeniTabulky.porovnatRetezce(NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(firstItem, this.nazevDataAtributu), NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(secondItem, this.nazevDataAtributu)));

			return filteredItems;
		}));

		this.vyfiltrovanePolozky.subscribe(items => {
			if (NastrojeObecne.jePrazdnaHodnota(this.gridTemplateColumns)) {
				this.gridTemplateColumns = NastrojeAutomatickehoDoplnovani.pripravitHodnotySloupcuGridSablony(this.nazvyDataAtributu, items);
			}
		});
	}

	private registrujChangeListenerForSettingHiddenControlValue(): void {
		if (!this.jeFormularovyPrvekSkryty()) {
			this.formController.valueChanges.subscribe(() => {
				if (!this.currentValueChangeOfControlIsTriggeredBySelectionChange) {
					this.zkontrolovatHodnotyViditelnychAutocompletePrvkuToSetHiddenControlValue();
				} else {
					this.currentValueChangeOfControlIsTriggeredBySelectionChange = false;
				}
			});
		}
	}

	private jeFormularovyPrvekSkryty(): boolean {
		return this.datovaStruktura.konfiguraceValidatoru.find(configuration => configuration.formControlName === this.nazevPrvkuFormulare).hideFormControl === true;
	}

	private zkontrolovatHodnotyViditelnychAutocompletePrvkuToSetHiddenControlValue(): void {
		const configurationWithHiddenControl = this.datovaStruktura.konfiguraceValidatoru.find(configuration => configuration.hideFormControl === true);
		const hiddenFormControl = this.parentFormGroup.controls[configurationWithHiddenControl.formControlName];

		const dataFirstMatch = this.datovaStruktura.data
			.find(dataObject => this.datovaStruktura.konfiguraceValidatoru
				.filter(configuration => !NastrojeObecne.jePrazdnaHodnota(configuration.formControlName) && !configuration.hideFormControl)
				.every(configuration => this.parentFormGroup.controls[configuration.formControlName].value === dataObject[configuration.dataAttributeName]));

		if (dataFirstMatch) {
			hiddenFormControl.setValue(dataFirstMatch[configurationWithHiddenControl.dataAttributeName]);
		} else if (!NastrojeObecne.jePrazdnaHodnota(hiddenFormControl.value)) {
			hiddenFormControl.setValue('');
		}
	}

	private filter(value: string): any {
		return this.datovaStruktura.data.filter(column => !NastrojeObecne.jePrazdnaHodnota(NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(column, this.nazevDataAtributu)) && NastrojeAutomatickehoDoplnovani.pripravitHodnotuAtributu(column, this.nazevDataAtributu).toString().toLowerCase().indexOf(value.toString().toLowerCase()) !== -1);
	}

	private smazatVsechnyMultipleAutocompleteHodnoty(): void {
		this.datovaStruktura.konfiguraceValidatoru.forEach(config => {
			const control = this.parentFormGroup.controls[config.formControlName];

			if (control && !NastrojeObecne.jePrazdnaHodnota(control.value)) {
				control.setValue('');
			}
		});
	}
}
