import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
	selector: 'app-modal-kontextove-napovedy',
	templateUrl: './modal-kontextove-napovedy.component.html',
	styleUrls: ['./modal-kontextove-napovedy.component.scss']
})
export class ModalKontextoveNapovedyComponent {

	constructor(
		private dialogRef: MatDialogRef<ModalKontextoveNapovedyComponent>,
		@Inject(MAT_DIALOG_DATA) public kontextovaNapoveda) {
	}

	public zavrit(): void {
		this.dialogRef.close();
	}
}
