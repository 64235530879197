import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-podnadpis',
	templateUrl: './podnadpis.component.html',
	styleUrls: ['./podnadpis.component.scss']
})
export class PodnadpisComponent {

	@Input() klicTextu: string;
	@Input() cssTrida = 'subheading subheading-margin-standard';
}
