<div class="checkbox input-field" [formGroup]="parentFormGroup">
	<mat-checkbox color="primary"
				  [formControlName]="nazevPrvkuFormulare"
				  [required]="jeHodnotaPovinna()"
				  [attr.disabled]="vypnoutPrvekFormulare()">{{ popisek | lokalizace }}</mat-checkbox>
	<app-ikona-kontextove-napovedy *ngIf="identifikatorPoleProKontextovouNapovedu"
								   class="komponentaIkonaKontextoveNapovedy"
								   [identifikatorPoleProKontextovouNapovedu]="identifikatorPoleProKontextovouNapovedu"></app-ikona-kontextove-napovedy>
	<mat-hint *ngIf="!pripravitErrorZpravu()">{{ napoveda | lokalizace }}</mat-hint>
	<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
</div>
