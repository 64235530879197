import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../../../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-select-boolean',
	templateUrl: './formular-select-boolean.component.html',
	styleUrls: ['./formular-select-boolean.component.scss']
})
export class FormularSelectBooleanComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Array of input items
	 */
	@Input() polozky: any;

	/**
	 * Defines not selected label
	 */
	@Input() nezvolenyPopisek = 'formularSelect.nezvoleno';

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
