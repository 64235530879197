import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-multiple-select',
	templateUrl: './formular-multiple-select.component.html',
	styleUrls: ['./formular-multiple-select.component.scss']
})
export class FormularMultipleSelectComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Array of input items
	 */
	@Input() polozky: any;

	/**
	 * Defines if items will be sorted
	 */
	@Input() seraditPolozky = true;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
