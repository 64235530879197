import {NastrojeRetezcu} from './nastroje-retezcu';

export class NastrojeCisel {

	public static readonly VYCHOZI_ZAOKROUHLENI = 0;
	public static readonly VYCHOZI_DESETINNY_ODDELOVAC = ',';
	public static readonly VZOR_DESETINNE_CISLO = '^(?=.*[0-9])(\\s|\\d)*([.,](\\s|\\d)*)?$';
	public static readonly VZOR_CELE_CISLO = '^[0-9]*$';
	public static readonly VZOR_CELE_CISLO_S_MEZERAMI = '^([0-9]+\\s)*[0-9]+$';

	public static prevestNaCislo(vstup: any): number {
		return vstup ? Number(NastrojeRetezcu.odstranitMezery(vstup.toString()).replace(',', '.')) : vstup;
	}

	public static formatovatCislo(hodnota: number, oddelovac: boolean = true, zaokrouhleni: number = this.VYCHOZI_ZAOKROUHLENI, desetinnyOddelovac = this.VYCHOZI_DESETINNY_ODDELOVAC): number {
		const konfigurace = {
			prefix: '',
			suffix: '',
			integerSeparator: ' ',
			decimal: desetinnyOddelovac,
			round: zaokrouhleni
		};

		return NastrojeCisel.formatovat(hodnota, konfigurace, {noSeparator: !oddelovac});
	}

	public static formatovat(hodnota: number, konfigurace: any, pretizeniKonfigurace?: any): number {
		const numberFormat = require('format-number');
		const format = numberFormat(konfigurace);

		return format(hodnota, pretizeniKonfigurace);
	}
}
