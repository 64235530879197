import {Pipe, PipeTransform} from '@angular/core';
import {KonfiguraceFormatuSloupce} from '../../data/table/table-configuration/konfigurace-formatu-sloupce';
import {DataType} from '../enum/data-type.enum';
import {NastrojeFormatovani} from '../nastroje/nastroje-formatovani';

@Pipe({
	name: 'formatovat'
})
export class FormatovatPipe implements PipeTransform {

	transform(hodnota: any, format: KonfiguraceFormatuSloupce = {typ: DataType.STRING}): any {
		return NastrojeFormatovani.formatovat(hodnota, format);
	}
}
