import {Injectable} from '@angular/core';
import {AbstraktniService, TypPozadavku} from './abstraktni.service';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from './bezpecnost/autentizace.service';
import {UpozorneniService} from './upozorneni.service';
import {prostredi} from '../../prostredi/prostredi';
import {lastValueFrom} from 'rxjs';

@Injectable()
export class NotifikaceDodavateluService extends AbstraktniService {

	private readonly cestaNaPrihlaseniKOdberuUpozorneni = '/iniciacni-email';

	constructor(
		protected http: HttpClient,
		protected autentizaceService: AutentizaceService,
		protected upozorneniService: UpozorneniService
	) {
		super(http, prostredi.urlNotifikaceDodavatelu, autentizaceService, upozorneniService);
	}

	public prihlasitSeKOdberuUpozorneni(email: string, lokalizace: string) {
		return lastValueFrom(this.pozadavekBezTokenu(TypPozadavku.POST, this.cestaNaPrihlaseniKOdberuUpozorneni, {email, lokalizace}));
	}
}
