import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';
import {TypEnumuPipe} from './typ-enumu.pipe';
import {NastrojeEnumu} from '../nastroje/nastroje-enumu';
import {NastrojeRazeniTabulky} from '../nastroje/nastroje-razeni-tabulky';

@Pipe({
	name: 'selectSort'
})
export class SelectSortPipe implements PipeTransform {

	transform(value: any, sort: boolean, enumType?: any, objectKeyName?: string): any {
		if (sort && value instanceof Array) {
			value.sort((firstItem, secondItem) => {
				if (!NastrojeObecne.jePrazdnaHodnota(enumType)) {
					const pipe = new TypEnumuPipe();
					const enumTypeAsString = NastrojeObecne.jeHodnotaRetezec(enumType) ? enumType.toString() : NastrojeEnumu.pripravitTriduEnumuJakoText(enumType);
					const firstItemTranslated = pipe.transform(firstItem.value, enumTypeAsString);
					const secondItemTranslated = pipe.transform(secondItem.value, enumTypeAsString);

					return NastrojeRazeniTabulky.porovnatRetezce(firstItemTranslated, secondItemTranslated);
				} else if (!NastrojeObecne.jePrazdnaHodnota(objectKeyName)) {
					return NastrojeRazeniTabulky.porovnatRetezce(firstItem[objectKeyName], secondItem[objectKeyName]);
				} else {
					return NastrojeRazeniTabulky.porovnatRetezce(firstItem.value, secondItem.value);
				}
			});
		}

		return value;
	}
}
