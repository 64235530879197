<div class="gov-modal__header">
	<h2 mat-dialog-title>{{ 'modalOdebiratUpozorneni.nadpis' | lokalizace }}</h2>
	<app-tlacitko-zavreni-modalu (click)="zavrit()"></app-tlacitko-zavreni-modalu>
</div>

<mat-dialog-content>
	<p>{{ 'modalOdebiratUpozorneni.popisekModalu' | lokalizace }}</p>
	<form class="form-column-layout">
		<app-formular-input class="span-12"
							[popisek]="'modalOdebiratUpozorneni.email'"
							[formController]="prvkyFormulare['emailovaAdresa']"></app-formular-input>
	</form>

	<mat-dialog-actions align="start">
		<button class="mat-button btn-primary-blue" mat-button (click)="submit()">{{ 'tlacitkoOdeslat' | lokalizace }}</button>
		<button class="mat-button btn-secondary-white" mat-button (click)="zavrit()">{{ 'tlacitkoZavrit' | lokalizace }}</button>
	</mat-dialog-actions>
</mat-dialog-content>

