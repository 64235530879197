<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input matInput
			   [formControlName]="nazevPrvkuFormulare"
			   [matDatepicker]="picker"
			   [required]="jeHodnotaPovinna()"
			   [attr.disabled]="vypnoutPrvekFormulare()">
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
		<app-ikona-kontextove-napovedy *ngIf="identifikatorPoleProKontextovouNapovedu"
									   class="komponentaIkonaKontextoveNapovedy"
									   [identifikatorPoleProKontextovouNapovedu]="identifikatorPoleProKontextovouNapovedu"></app-ikona-kontextove-napovedy>
	</mat-form-field>
</div>
