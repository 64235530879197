<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<mat-chip-list #chipList [formControlName]="nazevPrvkuFormulare">
			<mat-chip *ngFor="let item of polozky; let i=index" (removed)="odebrat(i)">
				{{ item }}
				<mat-icon matChipRemove>cancel</mat-icon>
			</mat-chip>
			<input placeholder="{{ placeholder | lokalizace }}"
				   autocomplete="off"
				   [matChipInputFor]="chipList"
				   [matChipInputSeparatorKeyCodes]="kodyOddelovacu"
				   (matChipInputTokenEnd)="pridat($event)"
				   [attr.disabled]="vypnoutPrvekFormulare()"
				   [required]="jeHodnotaPovinna()">
		</mat-chip-list>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
