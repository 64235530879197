import {Polozka} from '../../data/polozka';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeUrl {

	public static readonly VZOR_URL = /^https?:\/\//;

	public static otevritUrlVeStejneZalozce(url: string): void {
		window.open(url, '_self');
	}

	public static pripravitUrl(korenovaUrl: string, cesta?: string, parametry?: Polozka[]): string {
		return NastrojeObecne.formatovatText('{0}{1}{2}', korenovaUrl, this.pripravitCestu(cesta), this.pripravitUrlParametryJakoText(parametry));
	}

	private static pripravitCestu(cesta?: string): string {
		return NastrojeObecne.jePrazdnaHodnota(cesta) ? '' : String(String(cesta).startsWith('/') ? cesta : '/' + cesta);
	}

	private static pripravitUrlParametryJakoText(parametry: Polozka[]): string {
		let value = '';

		if (parametry) {
			for (const item of parametry) {
				const delimiter = value.length === 0 ? '?' : '&';

				value += NastrojeObecne.formatovatText('{0}{1}={2}', delimiter, item.key, item.value);
			}
		}

		return value;
	}
}
