import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import Keycloak from 'keycloak-js';
import {prostredi} from '../../../prostredi/prostredi';
import {NastrojeAutentizace} from '../../shared/nastroje/nastroje-autentizace';
import {NastrojeJsonu} from '../../shared/nastroje/nastroje-jsonu';
import {NastrojeObecne} from '../../shared/nastroje/nastroje-obecne';
import {Role} from '../../data/sdilene/enum/role.enum';
import {NastrojeLokalizace} from '../../shared/nastroje/nastroje-lokalizace';

@Injectable()
export class AutentizaceService {

	constructor(private http: HttpClient, private keycloakService: KeycloakService) {
	}

	public pridatTokenDoHlavicek(hlavicky?: HttpHeaders): HttpHeaders {
		if (!hlavicky) {
			hlavicky = new HttpHeaders();
		}

		hlavicky = hlavicky.append('Authorization', 'Bearer ' + this.keycloakService.getKeycloakInstance().token);

		return hlavicky;
	}

	public aktualizovatInformaceOUzivateliVLokalnimUlozisti(): void {
		NastrojeAutentizace.ulozitInformaceOUzivateliDoLokalnihoUloziste(NastrojeJsonu.prevestNaTextAParsovat(this.keycloakService.getKeycloakInstance().profile));
	}

	public prihlasit(): void {
		NastrojeAutentizace.odstranitInformaceOUzivateliZLokalnihoUloziste();

		this.keycloakService.getKeycloakInstance().login({locale: NastrojeLokalizace.pripravitLokalizaci()}).then();
	}

	public odhlasit(): void {
		NastrojeAutentizace.odstranitInformaceOUzivateliZLokalnihoUloziste();

		this.keycloakService.getKeycloakInstance().logout({redirectUri: prostredi.urlVerejneAplikace}).then();
	}

	public jeUzivatelPrihlasen(): boolean {
		return this.keycloakService.getKeycloakInstance().authenticated && !this.keycloakService.getKeycloakInstance()
			.isTokenExpired();
	}

	public pripravitJmenoAPrijmeni(): string {
		const informaceOUzivateli = this.pripravitInformaceOUzivateli();

		return informaceOUzivateli ? NastrojeObecne.formatovatText('{0} {1}', informaceOUzivateli.firstName, informaceOUzivateli.lastName) : undefined;
	}

	public pripravitPrihlasovaciJmeno(): string {
		return this.pripravitInformaceOUzivateli()?.username;
	}

	public pripravitIdSubjektu(): string {
		if (this.jeUzivatelPrihlasen()) {
			return this.pripravitInformaceOUzivateli()['attributes']['subjektId'][0];
		}

		return null;
	}

	public rolePrihlasenehoUzivateleJeUzivatelSubjektu(): boolean {
		return this.porovnatRoliPrihlasenehoUzivatele(Role.UZIVATEL_SUBJEKTU);
	}

	public rolePrihlasenehoUzivateleJeAdministratorSubjektu(): boolean {
		return this.porovnatRoliPrihlasenehoUzivatele(Role.ADMINISTRATOR_SUBJEKTU);
	}

	private porovnatRoliPrihlasenehoUzivatele(role: Role): boolean {
		if (this.jeUzivatelPrihlasen()) {
			return this.pripravitInformaceOUzivateli()['attributes']['role'][0] === role;
		}

		return false;
	}

	private pripravitInformaceOUzivateli(): Keycloak.KeycloakProfile {
		return NastrojeAutentizace.pripravitInformaceOUzivateliZLokalnihoUloziste();
	}
}
