<div class="gov-modal__header">
	<h2 mat-dialog-title>{{ data.nadpis | lokalizace}}</h2>
	<app-tlacitko-zavreni-modalu [mat-dialog-close]="false"></app-tlacitko-zavreni-modalu>
</div>

<mat-dialog-content mat-dialog-content>
	<p>{{ data.text | lokalizace}}</p>

	<mat-dialog-actions align="start">
		<button class="mat-button btn-primary-blue" mat-button cdkFocusInitial *ngIf="data.popisekTlacitkaAkce" [mat-dialog-close]="true">{{ (data.popisekTlacitkaAkce ? data.popisekTlacitkaAkce : 'tlacitkoZavrit') | lokalizace }}</button>
		<button class="mat-button btn-secondary-white" mat-button [mat-dialog-close]="false">{{ 'tlacitkoZavrit' | lokalizace }}</button>
	</mat-dialog-actions>
</mat-dialog-content>
