<app-vrtitko class="global-spinner"></app-vrtitko>

<main>
	<nav>
		<app-hlavicka></app-hlavicka>
	</nav>
	<section class="content">
		<router-outlet></router-outlet>
	</section>
	<app-paticka></app-paticka>
</main>
