import {Pipe, PipeTransform} from '@angular/core';
import moment from 'moment';

@Pipe({
	name: 'datum'
})
export class DatumPipe implements PipeTransform {

	transform(hodnota: any, format: string = ''): string {
		moment.locale('cs');

		if (!hodnota || hodnota === '') {
			return '';
		}

		return moment(hodnota).format(format);
	}
}
