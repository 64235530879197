import {Injectable} from '@angular/core';
import {AbstraktniService, TypPozadavku} from './abstraktni.service';
import {prostredi} from '../../prostredi/prostredi';
import {HttpClient} from '@angular/common/http';
import {AutentizaceService} from './bezpecnost/autentizace.service';
import {UpozorneniService} from './upozorneni.service';
import {lastValueFrom} from 'rxjs';
import {NastrojeObecne} from '../shared/nastroje/nastroje-obecne';
import {NastrojeRetezcu} from '../shared/nastroje/nastroje-retezcu';

@Injectable({
	providedIn: 'root'
})
export class KontextoveNapovedyService extends AbstraktniService {

	private readonly typFormulare = 'REGISTRACE';
	private readonly vzorCestaKontextoveNapovede = `/text-napovedy/${this.typFormulare}/{0}`;

	constructor(
		protected http: HttpClient,
		protected autentizaceService: AutentizaceService,
		protected upozorneniService: UpozorneniService) {
		super(http, prostredi.urlKontextovaNapoveda, autentizaceService, upozorneniService);
	}

	public nacistKontextovouNapovedu(idNapovedy): Promise<{text: string}> {
		return lastValueFrom(this.pozadavekBezTokenu(TypPozadavku.GET, NastrojeObecne.formatovatText(this.vzorCestaKontextoveNapovede, NastrojeRetezcu.zakodovatRetezecDoBase64(idNapovedy))));
	}
}
