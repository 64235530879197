<div class="gov-modal__header">
	<h2 mat-dialog-title>{{ 'modalKontextovaNapoveda.nadpis' | lokalizace }}</h2>
	<app-tlacitko-zavreni-modalu (click)="zavrit()"></app-tlacitko-zavreni-modalu>
</div>

<mat-dialog-content>
	<p>{{ kontextovaNapoveda }}</p>

	<mat-dialog-actions align="start">
		<button class="mat-button btn-secondary-white" mat-button (click)="zavrit()">{{ 'tlacitkoZavrit' | lokalizace }}</button>
	</mat-dialog-actions>
</mat-dialog-content>
