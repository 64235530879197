<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input matInput [type]="ukazHeslo ? 'text' : 'password'"
			   placeholder="{{ placeholder | lokalizace }}"
			   [formControlName]="nazevPrvkuFormulare"
			   [attr.disabled]="vypnoutPrvekFormulare()"
			   [required]="jeHodnotaPovinna()">
		<mat-icon matSuffix (click)="prepnoutViditelnostHesla()">{{ ukazHeslo ? 'visibility_off' : 'visibility' }}</mat-icon>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
