import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeObecneSoubory} from '../nastroje/nastroje-obecne-soubory';

@Pipe({
	name: 'velikostSouboru'
})
export class VelikostSouboruPipe implements PipeTransform {

	transform(hodnota: number): string {
		return NastrojeObecneSoubory.pripravitVelikostSouboruJakoText(hodnota);
	}
}
