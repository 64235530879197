import {Component, HostBinding, HostListener} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';
import {UpozorneniService} from '../../service/upozorneni.service';
import {NastrojeFormularu} from '../nastroje/nastroje-formularu';
import {TypUpozorneni} from '../../data/general/enum/typ-upozorneni.enum';
import {NastrojeCisel} from '../nastroje/nastroje-cisel'

@Component({template: ''})
export abstract class AbstraktniFormularComponent {

	protected abstract formBuilder: UntypedFormBuilder;

	private readonly id = NastrojeObecne.vygenerovatNahodnyRetezec();
	private readonly klicVychoziChyboveHlasky = 'formular.chyba.formularObsahujeChyby';
	private readonly kodEnterKlavesy = 13;

	public formGroup: UntypedFormGroup;
	public prvkyFormulare: any;

	@HostBinding('attr.id') get getId(): any {
		return this.id;
	}

	@HostListener('keypress', ['$event']) onKeyup(event: any): void {
		const kod = event.which || event.keyCode;

		if (kod === this.kodEnterKlavesy && this.formGroup && NastrojeFormularu.jeElementTextovyVstup(event.target) && NastrojeFormularu.jePrvekSoucastiTohotoFormulare(event.target, this.id) && !NastrojeFormularu.jePrvekSoucastiFiltruDatoveTabulky(event.target)) {
			this.submit();
		}
	}

	protected constructor(protected notificationService: UpozorneniService) {
	}

	public inicializovatFormular(): void {
		this.formGroup = this.formBuilder.group([]);
		this.prvkyFormulare = this.formGroup.controls;

		NastrojeFormularu.oznacitJakoNeodeslane(this.formGroup);
	}

	public submit(): void {
		NastrojeFormularu.oznacitJakoOdeslane(this.formGroup);
		this.validovatFormular();

		if (this.formGroup.valid) {
			this.prevestPrazdneHodnotyNaUndefined();
			this.prevestRetezceSCislyAMezeremiNaCislo();
			this.prevestDesetinnouCarkuNaTecku();
			this.send();
		} else {
			this.notificationService.upozornit(TypUpozorneni.CHYBA, this.klicVychoziChyboveHlasky);
		}
	}

	public resetovatFormular() {
		NastrojeFormularu.resetovatDataFormulare(this.formGroup);
	}

	private validovatFormular(): void {
		Object.keys(this.formGroup.controls).forEach(key => {
			this.formGroup.controls[key].markAsTouched();
			this.formGroup.controls[key].updateValueAndValidity();
		});
	}

	private prevestPrazdneHodnotyNaUndefined() {
		Object.keys(this.formGroup.controls).forEach(key => {
			if (this.formGroup.get(key).value === '') {
				NastrojeFormularu.nastavitHodnotuPouzeSobeBezVyslaniUdalosti(this.formGroup.get(key), undefined);
			}
		});
	}

	private prevestRetezceSCislyAMezeremiNaCislo() {
		Object.keys(this.formGroup.controls).forEach(key => {

			const currentControl = this.formGroup.get(key);
			const validators = (currentControl as any)._rawValidators;

			if (!NastrojeObecne.jePrazdnaHodnotaNeboPrazdnePole(validators) && !NastrojeObecne.jePrazdnaHodnota(currentControl.value)) {
				for (const validator of validators) {
					if (validator(new UntypedFormControl('validator', []))?.pattern?.requiredPattern === NastrojeCisel.VZOR_CELE_CISLO_S_MEZERAMI) {
						currentControl.setValue(NastrojeObecne.jePrazdnaHodnota(currentControl.value.toString()) ? '' : NastrojeCisel.prevestNaCislo(currentControl.value));
					}
				}
			}
		});
	}

	private prevestDesetinnouCarkuNaTecku() {
		Object.keys(this.formGroup.controls).forEach(key => {
			const currentControl = this.formGroup.get(key);
			const validators = (currentControl as any)._rawValidators;

			if (!NastrojeObecne.jePrazdnaHodnotaNeboPrazdnePole(validators)) {
				for (const validator of validators) {
					if (validator(new UntypedFormControl('validator', []))?.pattern?.requiredPattern === NastrojeCisel.VZOR_DESETINNE_CISLO) {
						currentControl.setValue(NastrojeCisel.prevestNaCislo(currentControl.value));
					}
				}
			}
		});
	}

	abstract send(): void;
}
