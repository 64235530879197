import {TypLokalizace} from '../../data/general/enum/typ-lokalizace.enum';
import {NastrojeLokalnihoUloziste} from './nastroje-lokalniho-uloziste';

export class NastrojeLokalizace {

	public static readonly NAZEV_KLICE_HODNOTY_LOKALIZACE_V_LOKALNIM_ULOZISTI = 'lokalizace';
	public static readonly VSECHNY_MOZNOSTI_LOKALIZACE = [TypLokalizace.CZ, TypLokalizace.EN];
	public static readonly NAZVY_VSECH_MOZNOSTI_LOKALIZACE = ['CZ', 'EN'];

	private static readonly vychoziLokalizace = TypLokalizace.CZ;

	public static nastavitLokalizaci(typ: TypLokalizace): void {
		NastrojeLokalnihoUloziste.vytvoritHodnotu(NastrojeLokalizace.NAZEV_KLICE_HODNOTY_LOKALIZACE_V_LOKALNIM_ULOZISTI, typ);
		location.reload();
	}

	public static pripravitLokalizaci(): TypLokalizace {
		if (!NastrojeLokalizace.jeLokalizaceInicializovana()) {
			NastrojeLokalizace.inicializovatLokalizaci();
		}

		return NastrojeLokalnihoUloziste.pripravitHodnotu(NastrojeLokalizace.NAZEV_KLICE_HODNOTY_LOKALIZACE_V_LOKALNIM_ULOZISTI) as TypLokalizace;
	}

	private static jeLokalizaceInicializovana(): boolean {
		return NastrojeLokalnihoUloziste.pripravitHodnotu(NastrojeLokalizace.NAZEV_KLICE_HODNOTY_LOKALIZACE_V_LOKALNIM_ULOZISTI) !== null;
	}

	private static inicializovatLokalizaci(): void {
		NastrojeLokalizace.nastavitLokalizaci(NastrojeLokalizace.vychoziLokalizace);
	}
}
