import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'app-tlacitko-zavreni-modalu',
	templateUrl: './tlacitko-zavreni-modalu.component.html',
	styleUrls: ['./tlacitko-zavreni-modalu.component.scss']
})
export class TlacitkoZavreniModaluComponent implements OnInit {

	constructor() {
	}

	ngOnInit(): void {
	}
}
