import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {NastrojeValidace} from '../../../nastroje/nastroje-validace';

@Component({template: ''})
export abstract class AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Represents data structure of the form
	 */
	@Input() formController: UntypedFormControl;

	/**
	 * Form element label
	 */
	@Input() popisek: string;

	/**
	 * Form element placeholder
	 */
	@Input() placeholder: string;

	/**
	 * Text displayed below the form element
	 */
	@Input() napoveda: string;

	/**
	 * For hide text displayed below the form element
	 */
	@Input() skrytNapovedu = false;

	/**
	 * Display help text part of the error
	 */
	@Input() napovedaSChybou = false;

	/**
	 * Name of form element in data structure of the form
	 */
	public nazevPrvkuFormulare: string;

	/**
	 * Every form element must be included in data structure of FormGroup
	 */
	public parentFormGroup: UntypedFormGroup;

	protected constructor() {
	}

	ngOnInit(): void {
		this.parentFormGroup = this.pripravitParentFormGroup();
		this.nazevPrvkuFormulare = this.pripravitNazevPrvkuFormulare();
	}

	public jeHodnotaPovinna(): boolean {
		return NastrojeValidace.jeNastavenValidatorPozadujiciHodnotu(this.formController);
	}

	public pripravitErrorZpravu(): string {
		if (this.zobrazitErrorZpravuPrvkuFormulare()) {
			return NastrojeValidace.pripravitTextErrorZpravy(this.formController, this.napovedaSChybou, this.napoveda);
		}

		return null;
	}

	public vypnoutPrvekFormulare(): boolean {
		return this.formController.disabled;
	}

	private zobrazitErrorZpravuPrvkuFormulare(): boolean {
		return this.zobrazitStavPrvkuFormulare() && this.formController.invalid;
	}

	private zobrazitStavPrvkuFormulare(): boolean {
		return !this.formController.disabled && (this.formController.touched || ((this.parentFormGroup as any).sent && this.formController.invalid));
	}

	private pripravitParentFormGroup(): UntypedFormGroup {
		return (this.formController as any)._parent;
	}

	private pripravitNazevPrvkuFormulare(): string {
		for (const nameOfFormControl of Object.keys(this.parentFormGroup.controls)) {
			if (this.formController === this.parentFormGroup.controls[nameOfFormControl]) {
				return nameOfFormControl;
			}
		}

		return null;
	}
}
