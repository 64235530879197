import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeEnumu} from '../nastroje/nastroje-enumu';

@Pipe({
	name: 'enumDleNazvu'
})
export class EnumDleNazvuPipe implements PipeTransform {

	transform(nazev: string): object {
		return NastrojeEnumu.pripravitEnumDleNazvuTridy(nazev);
	}
}
