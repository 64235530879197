export class NastrojePorovnani {

	public static porovnatDatumy(prvniDatum: Date, druheDatum: Date): number {
		if (new Date(prvniDatum).getTime() < new Date(druheDatum).getTime()) {
			return -1;
		}

		if (new Date(prvniDatum).getTime() > new Date(druheDatum).getTime()) {
			return 1;
		}

		return 0;
	}
}
