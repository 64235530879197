import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TypUpozorneni} from '../../../../../data/general/enum/typ-upozorneni.enum';
import {SouborFormulare} from '../../../../../data/general/file/soubor-formulare';
import {UpozorneniService} from '../../../../../service/upozorneni.service';
import {NastrojeObecneSoubory} from '../../../../nastroje/nastroje-obecne-soubory';
import {NastrojeFormularu} from '../../../../nastroje/nastroje-formularu';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {NastrojeTooltip} from '../../../../nastroje/nastroje-tooltip';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-soubor',
	templateUrl: './formular-soubor.component.html',
	styleUrls: ['./formular-soubor.component.scss']
})
export class FormularSouborComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	@ViewChild('addButton', { read: ElementRef }) addButton: ElementRef;

	/**
	 * Defines whether input allows multiple files or not
	 */
	@Input() viceSouboru = true;

	/**
	 * Defines allowed input file types
	 */
	@Input() povoleneSoubory = '*';

	@Input() maximalniPocetSouboru = null;

	/**
	 * Defines maximum size of one file in bytes
	 */
	@Input() maximalniVelikostJednohoSouboru = null;

	/**
	 * Definuje identifikator pre kontextovou napovedu
	 */
	@Input() identifikatorPoleProKontextovouNapovedu: string;

	public tabIndex = 0;

	constructor(private notificationService: UpozorneniService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		NastrojeTooltip.povolitHtmlObsah();
	}

	public async pridatVsechny(event: any): Promise<any> {
		const errors: any = [];

		this.inicializovatHodnotu();

		for (const file of event.target.files) {
			if (!NastrojeObecne.jePrazdnaHodnota(this.maximalniVelikostJednohoSouboru) && file.size > this.maximalniVelikostJednohoSouboru) {
				errors.push({klic: 'formular.chyba.nepodariloSePridatSoubor', parametry: [file.name]});
				continue;
			}

			if (this.formController.value.map((soubor: SouborFormulare) => soubor.nazev).includes(file.name)) {
				errors.push({klic: 'formular.chyba.souborBylJizPridan', parametry: [file.name]});
				continue;
			}

			await NastrojeObecneSoubory.pripravitSouborJakoBase64Data(file).then(data => {
				this.formController.value.push(NastrojeObecneSoubory.pripravitSouborJakoSouborFormulare(file, data));
			}).catch(() => errors.push({klic: 'formular.chyba.nepodariloSePridatSoubor', parametry: [file.name]}));
		}

		if (!NastrojeObecne.jePrazdnaHodnotaNeboPrazdnePole(errors)) {
			this.notificationService.upozornitVicenasobne(TypUpozorneni.CHYBA, errors);
		}

		event.target.value = '';
		this.aktualizovatPrvky();
	}

	public odstranitVsechny(): void {
		this.formController.setValue([]);

		this.aktualizovatPrvky();
	}

	public remove(index: number): void {
		this.formController.value.splice(index, 1);

		this.aktualizovatPrvky();
	}

	public stahnout(inputFile: SouborFormulare): void {
		if (inputFile.data) {
			const fileSaver = require('file-saver');
			fileSaver.saveAs(NastrojeObecneSoubory.pripravitBase64JakoSoubor(inputFile.data, inputFile.nazev, require('mime').getType(inputFile.nazev)));
		}
	}

	public jePrazdny(): boolean {
		return NastrojeObecne.jePrazdnaHodnotaNeboPrazdnePole(this.formController.value);
	}

	public zobrazitChipListFocus(): void {
		this.tabIndex = 0;
	}

	public vypnoutChipListFocus(): void {
		this.tabIndex = -1;
	}

	private inicializovatHodnotu(): void {
		if (NastrojeObecne.jePrazdnaHodnota(this.formController.value)) {
			this.formController.setValue([]);
		}
	}

	private aktualizovatPrvky(): void {
		NastrojeFormularu.oznacitPrvekJakoDotceny(this.formController);
		NastrojeFormularu.aktualizovatPrvekPouzeSobeBezVyslaniUdalosti(this.formController);
		this.addButton.nativeElement.focus();
	}
}
