import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatToolbarModule} from '@angular/material/toolbar';
import {PipesModule} from '../pipes/pipes.module';
import {HlavickaComponent} from './hlavicka/hlavicka.component';
import {PatickaComponent} from './paticka/paticka.component';
import {NadpisComponent} from './templates/nadpis/nadpis.component';
import {PodnadpisComponent} from './templates/podnadpis/podnadpis.component';
import {TextovyModalniPanelComponent} from './templates/textovy-modalni-panel/textovy-modalni-panel.component';
import {VrtitkoComponent} from './templates/vrtitko/vrtitko.component';
import {FormModule} from '../form/form.module';
import {Lokalizace} from '../lokalizace';
import {TlacitkoZavreniModaluComponent} from './templates/tlacitko-zavreni-modalu/tlacitko-zavreni-modalu.component';
import {FormsModule} from '@angular/forms';
import {ModalKontextoveNapovedyComponent} from './templates/modal-kontextove-napovedy/modal-kontextove-napovedy.component';

@NgModule({
	declarations: [
		VrtitkoComponent,
		NadpisComponent,
		PodnadpisComponent,
		TextovyModalniPanelComponent,
		HlavickaComponent,
		PatickaComponent,
		TlacitkoZavreniModaluComponent,
		ModalKontextoveNapovedyComponent
	],
	imports: [
		CommonModule,
		RouterModule,
		PipesModule,
		FormModule,
		MatButtonModule,
		MatMenuModule,
		MatToolbarModule,
		MatIconModule,
		MatProgressSpinnerModule,
		MatDividerModule,
		MatDialogModule,
		MatBadgeModule,
		FormsModule
	],
	exports: [
		VrtitkoComponent,
		NadpisComponent,
		PodnadpisComponent,
		TextovyModalniPanelComponent,
		HlavickaComponent,
		PatickaComponent,
		TlacitkoZavreniModaluComponent,
		ModalKontextoveNapovedyComponent
	],
	providers: [
		Lokalizace
	]
})
export class LayoutModule {
}
