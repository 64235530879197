import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalKontextoveNapovedyComponent} from '../modal-kontextove-napovedy/modal-kontextove-napovedy.component';
import {NastrojeModalu} from '../../../nastroje/nastroje-modalu';
import {KontextoveNapovedyService} from '../../../../service/kontextove-napovedy.service';

@Component({
	selector: 'app-ikona-kontextove-napovedy',
	templateUrl: './ikona-kontextove-napovedy.component.html',
	styleUrls: ['./ikona-kontextove-napovedy.component.scss']
})
export class IkonaKontextoveNapovedyComponent {

	/**
	 * Definuje identifikator pre kontextovou napovedu
	 */
	@Input() identifikatorPoleProKontextovouNapovedu: string;

	constructor(private dialog: MatDialog, private kontextoveNapovedyService: KontextoveNapovedyService) {
	}

	public pripravitNapoveduAOtevritModal(event: MouseEvent): void {
		event.stopPropagation();

		this.kontextoveNapovedyService.nacistKontextovouNapovedu(this.identifikatorPoleProKontextovouNapovedu)
			.then(napoveda => this.dialog.open(ModalKontextoveNapovedyComponent, NastrojeModalu.pripravitKonfiguraciModalu(napoveda.text, NastrojeModalu.MALY_MODAL)));
	}
}
