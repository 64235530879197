import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-radio',
	templateUrl: './formular-radio.component.html',
	styleUrls: ['./formular-radio.component.scss']
})
export class FormularRadioComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	@Input() polozky: any;
	@Input() neaktivniPolozky: any[];

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
