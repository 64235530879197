import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {TextovyModalniPanelData} from '../../../../data/modalni-panel/textovy-modalni-panel-data';

@Component({
	selector: 'app-textovy-modalni-panel',
	templateUrl: './textovy-modalni-panel.component.html',
	styleUrls: ['./textovy-modalni-panel.component.scss']
})
export class TextovyModalniPanelComponent {

	constructor(@Inject(MAT_DIALOG_DATA) public data: TextovyModalniPanelData) {
	}

	public static pripravitData(nadpisModalu: string, textModalu: string, nazevTlacitkaModalu: string): TextovyModalniPanelData {
		return {
			nadpis: nadpisModalu,
			text: textModalu,
			popisekTlacitkaAkce: nazevTlacitkaModalu
		};
	}
}
