import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';
import {NastrojeEnumu} from '../nastroje/nastroje-enumu';
import {Lokalizace} from '../lokalizace';

@Pipe({
	name: 'typEnumu'
})
export class TypEnumuPipe implements PipeTransform {

	transform(hodnota: any, typEnumu: any): string {
		if (!NastrojeObecne.jePrazdnaHodnota(hodnota) && !NastrojeObecne.jePrazdnaHodnota(typEnumu)) {
			const enumTypeAsString = NastrojeObecne.jeHodnotaRetezec(typEnumu) ? typEnumu.toString() : NastrojeEnumu.pripravitTriduEnumuJakoText(typEnumu);

			if (!NastrojeObecne.jePrazdnaHodnota(enumTypeAsString)) {
				return Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicHodnotyEnumuProLokalizaci(enumTypeAsString, hodnota));
			}
		}

		return hodnota;
	}
}
