import {Pipe, PipeTransform} from '@angular/core';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';

@Pipe({
	name: 'zvyraznit'
})
export class ZvyraznitPipe implements PipeTransform {

	transform(text: string, search: string): string {
		if (NastrojeObecne.jePrazdnaHodnota(text)) {
			return text;
		}

		if (NastrojeObecne.jePrazdnaHodnota(search)) {
			return text.toString();
		}

		const pattern = search.toString().replace(/[\-\[\]\/{}()*+?.\\^$|]/g, '\\$&').split(' ').filter(t => t.length > 0).join('|');
		const regex = new RegExp(pattern, 'gi');

		return search ? text.toString().replace(regex, match => `<b>${match}</b>`) : text.toString();
	}
}
