<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<textarea matInput cdkTextareaAutosize
				  placeholder="{{ placeholder | lokalizace }}"
				  [formControlName]="nazevPrvkuFormulare"
				  [required]="jeHodnotaPovinna()"
				  [attr.disabled]="vypnoutPrvekFormulare()"></textarea>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
