import {KonfiguraceModalu} from '../../data/modal/konfigurace-modalu';

export class NastrojeModalu {

	public static readonly ZAPNOUT_AUTOFOCUS = false;
	public static readonly VYPNOUT_ZAVRENI_MODALU = true;
	public static readonly VYCHOZI_VELIKOST_MODALU = 'auto';
	public static readonly VELKY_MODAL = '90vw';
	public static readonly STREDNI_MODAL = '65vw';
	public static readonly MALY_MODAL = '45vw';

	public static pripravitKonfiguraciModalu(data: any, velikostModalu = this.VYCHOZI_VELIKOST_MODALU, vypnoutZavreniModalu= this.VYPNOUT_ZAVRENI_MODALU, autoFocus = this.ZAPNOUT_AUTOFOCUS): KonfiguraceModalu {
		return {
			disableClose: vypnoutZavreniModalu,
			data: data,
			width: velikostModalu,
			autoFocus: autoFocus
		};
	}
}
