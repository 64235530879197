import {Component, OnInit} from '@angular/core';
import {NastrojeDataACasu} from '../../../../nastroje/nastroje-data-a-casu';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

/**
 * How to use:
 * <app-form-clock class="<name_of_class_which_defines_form_element_span>"
 * 				   [label]="<key_of_label>"
 * 				   [formController]="<form_control_name>"
 * 				   [placeHolder]="<placeholder_key>"
 * 				   [helpText]="<help_text_key>"></app-form-clock>
 */
@Component({
	selector: 'app-formular-hodin',
	templateUrl: './formular-hodin.component.html',
	styleUrls: ['./formular-hodin.component.scss']
})
export class FormularHodinComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	public readonly format = 24;
	public readonly vypnoutZavreni = true;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	public zmenitHodnotuPrvkuFormulare(time: string): void {
		this.formController.setValue(time);
	}

	public formatovatVstupniHodnotu(): void {
		this.formController.setValue(NastrojeDataACasu.prevestTextNaCas(this.formController.value));
	}
}
