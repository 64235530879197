import {Component, HostListener, OnInit} from '@angular/core';
import {AutentizaceService} from '../../../service/bezpecnost/autentizace.service';
import {TypLokalizace} from '../../../data/general/enum/typ-lokalizace.enum';
import {NastrojeLokalizace} from '../../nastroje/nastroje-lokalizace';
import {prostredi} from '../../../../prostredi/prostredi';
import {NastrojeModalu} from '../../nastroje/nastroje-modalu';
import {MatDialog} from '@angular/material/dialog';
import {ModalOdebiratUpozorneniComponent} from '../../../layout/modal-odebirat-upozorneni/modal-odebirat-upozorneni.component';

@Component({
	selector: 'app-hlavicka',
	templateUrl: './hlavicka.component.html',
	styleUrls: ['./hlavicka.component.scss']
})
export class HlavickaComponent implements OnInit {

	@HostListener('window:resize', ['$event']) onResize() {
		this.skrytMobilniMenuDleSirkyObrazovky();
	}

	@HostListener('click', ['$event.target']) onClick(tlacitko) {
		this.deaktivovatMobilniMenu(tlacitko);
	}

	private readonly maximalniSirkaMobilniObrazovky = 900;

	public readonly seznamLokalizaci = NastrojeLokalizace.VSECHNY_MOZNOSTI_LOKALIZACE;
	public readonly seznamNazvuLokalizaci = NastrojeLokalizace.NAZVY_VSECH_MOZNOSTI_LOKALIZACE;
	public readonly urlVerejneAplikace = prostredi.urlVerejneAplikace;

	private readonly verzeProstredi = prostredi.verze;
	private readonly refVerzeProstredi = 'Referencni';

	public jeUzivatelPrihlasen: boolean;
	public jmenoAPrijmeni: string;
	public rolePrihlasenehoUzivateleJeAdministratorSubjektu: boolean;
	public nastavenaLokalizace = NastrojeLokalizace.pripravitLokalizaci();
	public jeMobilniMenuAktivni: boolean = false;
	public zobrazitHlavickuVReferecnimModu: boolean = false;

	constructor(
		private autentizaceService: AutentizaceService,
		private modal: MatDialog
	) {
	}

	ngOnInit(): void {
		this.pripravitInformaceOUzivateli();
		this.pripravitZobrazitHlavickuVReferecnimModu();
	}

	public prihlasit(): void {
		this.autentizaceService.prihlasit();
	}

	public odhlasit(): void {
		this.autentizaceService.odhlasit();
	}

	public aktivovatMobilniMenu(): void {
		this.jeMobilniMenuAktivni = !this.jeMobilniMenuAktivni;
	}

	public deaktivovatMobilniMenu(tlacitko): void {
		if (tlacitko.classList.contains('gov-link')) {
			this.jeMobilniMenuAktivni = false;
		}
	}

	public prepnoutLokalizaci(event: any): void {
		NastrojeLokalizace.nastavitLokalizaci(event.target.value as TypLokalizace);
	}

	public otevritModalProOdberUpozorneni(): void {
		this.modal.open(ModalOdebiratUpozorneniComponent, NastrojeModalu.pripravitKonfiguraciModalu(null, NastrojeModalu.MALY_MODAL));
	}

	private pripravitInformaceOUzivateli(): void {
		this.jeUzivatelPrihlasen = this.autentizaceService.jeUzivatelPrihlasen();
		this.rolePrihlasenehoUzivateleJeAdministratorSubjektu = this.autentizaceService.rolePrihlasenehoUzivateleJeAdministratorSubjektu();
		this.jmenoAPrijmeni = this.autentizaceService.pripravitJmenoAPrijmeni();
	}

	private skrytMobilniMenuDleSirkyObrazovky(): void {
		if (this.jeMobilniMenuAktivni && window.innerWidth >= this.maximalniSirkaMobilniObrazovky) {
			this.jeMobilniMenuAktivni = false;
		}
	}

	private pripravitZobrazitHlavickuVReferecnimModu() {
		this.zobrazitHlavickuVReferecnimModu = this.verzeProstredi == this.refVerzeProstredi;
	}
}
