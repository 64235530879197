export class NastrojeRetezcu {

	public static odstranitMezery(hodnota: string): string {
		return hodnota.replace(new RegExp('\\s', 'g'), '');
	}

	public static odstranitDiakritiku(hodnota: string): string {
		return hodnota.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
	}

	public static prevestUpperSnakeCaseNaLowerCamelCase(hodnota: string): string {
		return hodnota.toLowerCase().replace(/_(\w)/g, (m => m[1].toUpperCase()));
	}

	public static prevestUpperCamelCaseNaLowerCamelCase(hodnota: string): string {
		return hodnota.charAt(0).toLowerCase() + hodnota.slice(1);
	}

	public static prevestFirstCharNaUpperCase(hodnota: string): string {
		return hodnota.charAt(0).toUpperCase() + hodnota.slice(1);
	}

	public static jeRetezecNullNeboUndefined(hodnota: string): boolean {
		return hodnota === null || hodnota === undefined || hodnota === 'null' || hodnota === 'undefined';
	}

	public static zakodovatRetezecDoBase64(retezecKZakodovani: string): string {
		return btoa(encodeURIComponent(retezecKZakodovani).replace(/%([0-9A-F]{2})/g, function(match, p1) {
			return String.fromCharCode(parseInt(p1, 16))
		}));
	}

	public static dekodovatRetezecZBase64(base64String: string): string {
		return decodeURIComponent(Array.prototype.map.call(atob(base64String), function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
	}
}
