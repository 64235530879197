import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'klice'
})
export class KlicePipe implements PipeTransform {

	transform(value: any): any {
		const keys = [];

		for (const enumMember of Object.keys(value)) {
			keys.push({
				key: enumMember.toString(),
				value: value[enumMember.toString()]
			});
		}

		return keys;
	}
}
