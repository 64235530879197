import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LayoutModule} from '../../shared/layout/layout.module';
import {PipesModule} from '../../shared/pipes/pipes.module';
import {DomuRoutingModule} from './domu-routing.module';
import {DomuComponent} from './domu.component';
import {MatDialogModule} from '@angular/material/dialog';
import {FormModule} from '../../shared/form/form.module';
import {MatButtonModule} from '@angular/material/button';
import {NotifikaceDodavateluService} from '../../service/notifikace-dodavatelu.service';
import {ModalOdebiratUpozorneniComponent} from '../modal-odebirat-upozorneni/modal-odebirat-upozorneni.component';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
	declarations: [
		DomuComponent,
		ModalOdebiratUpozorneniComponent
	],
	imports: [
		DomuRoutingModule,
		CommonModule,
		PipesModule,
		LayoutModule,
		PipesModule,
		MatDialogModule,
		FormModule,
		MatButtonModule,
		MatIconModule
	],
	providers: [
		NotifikaceDodavateluService
	]
})
export class DomuModule {
}
