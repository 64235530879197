import {TypVelikostiSouboru} from '../../data/general/enum/typ-velikosti-souboru.enum';
import {AbstraktniSoubor} from '../../data/general/file/abstraktni-soubor';
import {SouborFormulare} from '../../data/general/file/soubor-formulare';
import {NastrojeCisel} from './nastroje-cisel';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeObecneSoubory {

	public static readonly VYCHOZI_TYP_VELIKOSTI_SOUBORU = TypVelikostiSouboru.DECIMALNI;
	public static readonly VYCHOZI_ZAOKROUHLENI_VELIKOSTI_SOUBORU = 2;
	public static readonly VELIKOST_SOUBORU_DEKADICKE_JEDNOTKY = ['B', 'kB', 'MB', 'GB', 'TB'];
	public static readonly VELIKOST_SOUBORU_BINARNI_JEDNOTKY = ['B', 'KiB', 'MiB', 'GiB', 'TiB'];
	public static readonly DEKADICKY_NASOBITEL = 1000;
	public static readonly BINARNI_NASOBITEL = 1024;

	public static pripravitSouborFormulare(nazev: string, velikost: number, data: string): SouborFormulare {
		const souborFormulare = new SouborFormulare();

		souborFormulare.nazev = nazev;
		souborFormulare.velikost = velikost;
		souborFormulare.data = data;

		return souborFormulare;
	}

	public static pripravitSouborJakoSouborFormulare(soubor: File, data?: string): SouborFormulare {
		const souborFormulare = new SouborFormulare();

		souborFormulare.nazev = soubor.name;
		souborFormulare.velikost = soubor.size;
		souborFormulare.file = soubor;
		souborFormulare.data = data;

		return souborFormulare;
	}

	public static pripravitSouborJakoBase64Data(soubor: File): Promise<any> {
		const reader = new FileReader();

		return new Promise((resolve, reject) => {
			reader.addEventListener('load', function() {
				resolve((<string>reader.result).split(',')[1]);
			}, false);
			reader.addEventListener('error', function(event) {
				reject(event);
			}, false);

			reader.readAsDataURL(soubor);
		});
	}

	public static pripravitBase64JakoSoubor(base64Data: any, nazevSouboru: any, typObsahu: any): File {
		const sliceSize = 512;
		const byteCharacters = atob(base64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);
			const byteNumbers = new Array(slice.length);

			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			byteArrays.push(new Uint8Array(byteNumbers));
		}

		return new File(byteArrays, nazevSouboru, {type: typObsahu});
	}

	public static pripravitTextVelikostiSouboruJakoCislo(velikotSouboruJakoText: string, typVelikostiSouboru: TypVelikostiSouboru = TypVelikostiSouboru.DECIMALNI): number {
		const indexPosledniMezery = velikotSouboruJakoText.lastIndexOf(' ');
		const velikost = velikotSouboruJakoText.substring(0, indexPosledniMezery).replace(/\s/g, '').replace(',', '.');
		const jednotka = velikotSouboruJakoText.substring(indexPosledniMezery + 1);
		const hodnota = this.pripravitNasobitelVelikostiSouboru(typVelikostiSouboru);
		const exponent = this.pripravitIndexJednotkyVelikostiSouboru(jednotka, typVelikostiSouboru);

		return NastrojeObecne.jePrazdnaHodnota(velikost) ? 0 : parseFloat(velikost) * Math.pow(hodnota, exponent);
	}

	public static pripravitVelikostSouboruJakoText(velikostSouboru: number, typVelikostiSouboru = NastrojeObecneSoubory.VYCHOZI_TYP_VELIKOSTI_SOUBORU, zaokrouhleni = NastrojeObecneSoubory.VYCHOZI_ZAOKROUHLENI_VELIKOSTI_SOUBORU): string {
		const index = NastrojeObecneSoubory.pripravitIndex(velikostSouboru, typVelikostiSouboru);
		const hodnota = this.pripravitHodnotuVelikostiSouboru(velikostSouboru, typVelikostiSouboru, index);
		const jednotka = this.pripravitJednotkyVelikostiSouboru(typVelikostiSouboru)[index];

		return `${NastrojeCisel.formatovatCislo(hodnota, true, zaokrouhleni)} ${jednotka}`;
	}

	public static pripravitSouboryFormulareJakoAbstraktniSoubory(souboryFormulare: SouborFormulare[]): AbstraktniSoubor[] {
		return souboryFormulare.map(soubor => {
			delete soubor.file;

			return soubor;
		});
	}

	public static stahnoutSouborZURL(url: string, nazevSouboru: string): void {
		let elementOdkazu = document.createElement('a');

		elementOdkazu.href = url;
		elementOdkazu.download = nazevSouboru;
		elementOdkazu.target = '_blank';

		elementOdkazu.click();
	}

	private static pripravitIndexJednotkyVelikostiSouboru(jednotka: string, typVelikostiSouboru: TypVelikostiSouboru): number {
		switch (typVelikostiSouboru) {
			case TypVelikostiSouboru.DECIMALNI:
				return this.VELIKOST_SOUBORU_DEKADICKE_JEDNOTKY.indexOf(jednotka);
			case TypVelikostiSouboru.BINARNI_IEC:
				return this.VELIKOST_SOUBORU_BINARNI_JEDNOTKY.indexOf(jednotka);
		}
	}

	private static pripravitIndex(velikostSouboru: number, typVelikostiSouboru: TypVelikostiSouboru): number {
		const hodnota = NastrojeObecneSoubory.pripravitNasobitelVelikostiSouboru(typVelikostiSouboru);
		const jednotky = NastrojeObecneSoubory.pripravitJednotkyVelikostiSouboru(typVelikostiSouboru);

		return Math.trunc(Math.min(velikostSouboru > 0 ? (Math.log10(velikostSouboru) / Math.log10(hodnota)) : 0, jednotky.length - 1));
	}

	private static pripravitHodnotuVelikostiSouboru(velikostSouboru: number, typVelikostiSouboru: TypVelikostiSouboru, index: number): number {
		const hodnota = NastrojeObecneSoubory.pripravitNasobitelVelikostiSouboru(typVelikostiSouboru);

		return index === 0 ? velikostSouboru : velikostSouboru / Math.pow(hodnota, index);
	}

	private static pripravitNasobitelVelikostiSouboru(typVelikostiSouboru: TypVelikostiSouboru): number {
		switch (typVelikostiSouboru) {
			case TypVelikostiSouboru.DECIMALNI:
				return this.DEKADICKY_NASOBITEL;
			case TypVelikostiSouboru.BINARNI_IEC:
				return this.BINARNI_NASOBITEL;
		}
	}

	private static pripravitJednotkyVelikostiSouboru(typVelikostiSouboru: TypVelikostiSouboru): string[] {
		switch (typVelikostiSouboru) {
			case TypVelikostiSouboru.DECIMALNI:
				return this.VELIKOST_SOUBORU_DEKADICKE_JEDNOTKY;
			case TypVelikostiSouboru.BINARNI_IEC:
				return this.VELIKOST_SOUBORU_BINARNI_JEDNOTKY;
		}
	}
}
