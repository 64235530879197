import {HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize} from 'rxjs/operators';
import {UpozorneniService} from '../service/upozorneni.service';

import {NastrojeJsonu} from './nastroje/nastroje-jsonu';
import {NastrojeObecne} from './nastroje/nastroje-obecne';
import {NastrojePozadavku} from './nastroje/nastroje-pozadavku';

export const JE_POVOLENO_ZOBRAZENI_VRTITKA = new HttpContextToken<boolean>(() => true);

@Injectable()
export class HttpPozadavekInterceptor implements HttpInterceptor {

	private readonly korenovyElement = document.documentElement;
	private readonly nazevAtributuProPocetPozadavku = 'probihajici-pozadavky';

	constructor(private upozorneniService: UpozorneniService) {
	}

	intercept(httpPozadavek: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const jePovolenoZobrazeniVrtitka = httpPozadavek.context.get(JE_POVOLENO_ZOBRAZENI_VRTITKA);

		if (jePovolenoZobrazeniVrtitka) {
			this.upravitPocetPozadavku(1);
		}

		return next.handle(this.pripravitPozadavek(httpPozadavek))
			.pipe(catchError(httpError => {
				NastrojePozadavku.zpracovatChybu(this.upozorneniService, httpError);

				return throwError(httpError);
			}), finalize(() => {
				if (jePovolenoZobrazeniVrtitka) {
					this.upravitPocetPozadavku(-1);
				}
			}));
	}

	private upravitPocetPozadavku(zmena: number): void {
		const upravenyPocetPozadavku = (this.pripravitPocetProbihajicichPozadavku() || 0) + zmena;

		if (upravenyPocetPozadavku > 0) {
			this.korenovyElement.setAttribute(this.nazevAtributuProPocetPozadavku, upravenyPocetPozadavku.toString());
		} else {
			this.korenovyElement.removeAttribute(this.nazevAtributuProPocetPozadavku);
		}
	}

	private pripravitPocetProbihajicichPozadavku(): number {
		return +this.korenovyElement.getAttribute(this.nazevAtributuProPocetPozadavku);
	}

	private pripravitPozadavek(httpPozadavek: HttpRequest<any>): HttpRequest<any> {
		const teloPozadavku = httpPozadavek.body;

		if (NastrojeObecne.jePrazdnaHodnota(teloPozadavku) || !NastrojeObecne.jeHodnotaRetezec(teloPozadavku) || !NastrojeJsonu.jeTextJson(teloPozadavku)) {
			return httpPozadavek;
		}

		return httpPozadavek.clone({
			body: NastrojeJsonu.odstranitPrazdneHodnotyZJsonTextu(teloPozadavku)
		});
	}
}
