import {Pipe, PipeTransform} from '@angular/core';
import {SouborFormulare} from '../../data/general/file/soubor-formulare';
import {NastrojeObecne} from '../nastroje/nastroje-obecne';

@Pipe({
	name: 'vstupniSouborNaObrazek'
})
export class VstupniSouborNaObrazekPipe implements PipeTransform {

	private readonly mimeImageKeyword = 'image';
	private readonly dataUrlPattern = 'data:{0};base64, {1}';
	private readonly imagePattern = '<img width="100" height="auto" src="{0}"></image>';

	transform(inputFile: SouborFormulare): string {
		const mime = require('mime').getType(inputFile.nazev);

		if (mime?.includes(this.mimeImageKeyword)) {
			const dataUrl = NastrojeObecne.formatovatText(this.dataUrlPattern, mime, inputFile.data);

			return NastrojeObecne.formatovatText(this.imagePattern, dataUrl);
		}

		return undefined;
	}
}
