import {Component} from '@angular/core';
import {NastrojeDataACasu} from '../../nastroje/nastroje-data-a-casu';
import {NastrojeOdrolovani} from '../../nastroje/nastroje-odrolovani';
import {prostredi} from '../../../../prostredi/prostredi';

@Component({
	selector: 'app-paticka',
	templateUrl: './paticka.component.html',
	styleUrls: ['./paticka.component.scss']
})
export class PatickaComponent {

	public readonly aktualniRok = new Date();
	public readonly formatRoku = NastrojeDataACasu.FORMAT_ROKU;
	public readonly refVerzeProstredi = 'Referencni';
	public readonly urlVerejneAplikace = prostredi.urlVerejneAplikace;
	public readonly verzeProstredi = prostredi.verze;
	public readonly verzeCislo = prostredi.verzeCislo;

	public odskrolovatNaZacatekStranky(): void {
		NastrojeOdrolovani.odskrolovatNaZacatekStranky();
	}
}
