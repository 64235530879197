import {HttpClient, HttpContext, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PromiseObservable} from 'rxjs-compat/observable/PromiseObservable';
import {prostredi} from '../../prostredi/prostredi';
import {AutentizaceService} from './bezpecnost/autentizace.service';
import {Polozka} from '../data/polozka';
import {JE_POVOLENO_ZOBRAZENI_VRTITKA} from '../shared/http-pozadavek.interceptor';
import {NastrojeUrl} from '../shared/nastroje/nastroje-url';
import {UpozorneniService} from './upozorneni.service';

export abstract class AbstraktniService {

	protected hlavicky: HttpHeaders;

	protected constructor(protected http: HttpClient, protected url: string, protected autentizaceService: AutentizaceService, protected upozorneniService: UpozorneniService) {
		this.url = url;
		this.hlavicky = new HttpHeaders(prostredi.hlavickyBackend);
	}

	public pozadavekSTokenem(typPozadavku: TypPozadavku, cesta?: string, teloPozadavku?: any, parametry?: Polozka[], zobrazitVrtitko?: boolean): Observable<any> {
		return this.pozadavek(typPozadavku, true, teloPozadavku, cesta, parametry, zobrazitVrtitko);
	}

	public pozadavekBezTokenu(typPozadavku: TypPozadavku, cesta?: string, teloPozadavku?: any, parametry?: Polozka[], zobrazitVrtitko?: boolean): Observable<any> {
		return this.pozadavek(typPozadavku, false, teloPozadavku, cesta, parametry, zobrazitVrtitko);
	}

	private pozadavek(typPozadavku: TypPozadavku, pridatTokenDoHlavicek: boolean, teloPozadavku: string, cesta?: string, parametry?: Polozka[], zobrazitVrtitko?: boolean): Observable<any> {
		if (this.jeVyzadovanoPrihlaseni(pridatTokenDoHlavicek)) {
			return this.presmerovatNaPrihlaseni();
		}

		const urlPozadavku = NastrojeUrl.pripravitUrl(this.url, cesta, parametry);

		return this.pripravitPozadavek(typPozadavku, urlPozadavku, pridatTokenDoHlavicek, teloPozadavku, zobrazitVrtitko);
	}

	private pripravitPozadavek(typPozadavku: TypPozadavku, urlPozadavku: string, pridatTokenDoHlavicek: boolean, teloPozadavku: string | null, zobrazitVrtitko: boolean): Observable<any> {
		const nastaveni = this.pripravitNastaveni(pridatTokenDoHlavicek, zobrazitVrtitko);
		const nastaveniProStahovani = this.pripravitNastaveniProStahovani(pridatTokenDoHlavicek);

		switch (typPozadavku) {
			case TypPozadavku.GET:
				return this.http.get(urlPozadavku, nastaveni);
			case TypPozadavku.HEAD:
				return this.http.head(urlPozadavku, nastaveni);
			case TypPozadavku.PATCH:
				return this.http.patch(urlPozadavku, teloPozadavku, nastaveni);
			case TypPozadavku.POST:
				return this.http.post(urlPozadavku, teloPozadavku, nastaveni);
			case TypPozadavku.PUT:
				return this.http.put(urlPozadavku, teloPozadavku, nastaveni);
			case TypPozadavku.DELETE:
				return this.http.delete(urlPozadavku, Object.assign(nastaveni, {body: teloPozadavku}));
			case TypPozadavku.GET_BLOB:
				return this.http.get(urlPozadavku, nastaveniProStahovani);
			case TypPozadavku.POST_BLOB:
				return this.http.post(urlPozadavku, teloPozadavku, nastaveniProStahovani);
			default:
				return null;
		}
	}

	private pripravitNastaveni(pridatTokenDoHlavicek: boolean, zobrazitVrtitko: boolean = true): any {
		return {
			headers: pridatTokenDoHlavicek ? this.autentizaceService.pridatTokenDoHlavicek(this.hlavicky) : this.hlavicky,
			context: this.pripravitKontext(zobrazitVrtitko)
		};
	}

	private pripravitNastaveniProStahovani(pridatTokenDoHlavicek: boolean = false): any {
		const hlavickyStahovani = new HttpHeaders(prostredi.hlavickyBackendStahovani);

		return {
			headers: pridatTokenDoHlavicek ? this.autentizaceService.pridatTokenDoHlavicek(hlavickyStahovani) : hlavickyStahovani,
			reportProgress: true,
			observe: 'events',
			responseType: 'blob'
		};
	}

	private pripravitKontext(zobrazitVrtitko: boolean): HttpContext {
		return new HttpContext().set(JE_POVOLENO_ZOBRAZENI_VRTITKA, zobrazitVrtitko);
	}

	private presmerovatNaPrihlaseni(): Observable<any> {
		return PromiseObservable.create(new Promise(async () => {
			this.autentizaceService.prihlasit();
		}));
	}

	private jeVyzadovanoPrihlaseni(pridatTokenDoHlavicek: boolean): boolean {
		return pridatTokenDoHlavicek && !this.autentizaceService.jeUzivatelPrihlasen();
	}
}

export enum TypPozadavku {
	GET, HEAD, PATCH, POST, PUT, POST_BLOB, DELETE, GET_BLOB
}
