<footer class="gov-container gov-container--wide u-bg-color--grey-dark gov-footer">
	<div class="gov-container__content">
		<section class="gov-footer__upper u-screen--only">
			<div class="gov-footer__col"><h3 class="gov-footer__headline">{{ 'paticka.podpora' | lokalizace }}</h3>
				<ul class="gov-list--plain">
					<li class="gov-footer__li-supp">
						<span aria-hidden="true" class="gov-icon gov-icon--mail gov-footer__icon "></span>
						<a target="_blank" href="https://sd.nipez.cz/vvz?id=vvz_guest_question" class="gov-footer__supp">Kontaktní formulář</a>
<!--						<a href="mailto:podpora-vvz@nipez.cz" class="gov-footer__supp">podpora-vvz@nipez.cz</a>-->
					</li>
					<li class="gov-footer__li-supp">
						<span aria-hidden="true" class="gov-icon gov-icon--phone gov-footer__icon "></span>
						<a href="tel:+420211154370" class="gov-footer__supp">+420 211 154 370</a>
					</li>
					<li>(v pracovní dny od 8:00 do 18:00)</li>
				</ul>
			</div>
			<div class="gov-footer__col">
				<h3 class="gov-footer__headline">{{ 'paticka.informace' | lokalizace }}</h3>
				<ul class="gov-list--plain">
					<li><a class="gov-link gov-link--standalone gov-link--inversed"
						   href="{{ urlVerejneAplikace + '/napoveda-dokumentace/provozni-rad' }}">{{ 'paticka.informace.rad' | lokalizace }}</a></li>
					<li><a class="gov-link gov-link--standalone gov-link--inversed"
						   href="{{ urlVerejneAplikace + '/uzitecne-informace/prohlaseni-o-pristupnosti' }}">{{ 'paticka.informace.pristupnost' | lokalizace }}</a></li>
					<li><a class="gov-link gov-link--standalone gov-link--inversed"
						   href="{{ urlVerejneAplikace + '/uzitecne-informace/zpracovani-osobnich-udaju-a-cookies' }}">{{ 'paticka.informace.cookies' | lokalizace }}</a>
					</li>
				</ul>
			</div>
			<div class="gov-footer__col">
				<h3 class="gov-footer__headline">{{ 'paticka.duleziteOdkazy' | lokalizace }}</h3>
				<ul class="gov-list--plain">
					<li *ngIf="verzeProstredi == refVerzeProstredi">
						<a href="https://vvz.nipez.cz/" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.produkcniProstrediVVZ' | lokalizace }}</a>
					</li>
					<li>
						<a href="https://portal-vz.cz/" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.portalOVerejnychZakazkach' | lokalizace }}</a>
					</li>
					<li>
						<a href="https://nen.nipez.cz/" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.narodniElektronickyNastroj' | lokalizace }}</a>
					</li>
					<li>
						<a href="https://ted.europa.eu/TED/browse/browseByMap.do" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.uredniVestnikEu' | lokalizace }}</a>
					</li>
					<li>
						<a href="https://isvz.nipez.cz" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.informacniSystemOVerejnychZakazkach' | lokalizace }}</a>
					</li>
					<li>
						<a href="https://skd.nipez.cz/ISVZ/SKD/ISVZ_SKD_text.aspx" class="gov-link gov-link--standalone gov-link--inversed" target="_blank" rel="noreferrer noopener">{{ 'paticka.seznamKvalifikovanychDodavatelu' | lokalizace }}</a>
					</li>

				</ul>

			</div>


		</section>

		<hr>

		<section class="gov-footer__lower">
			<p class="gov-footnote">{{ aktualniRok | datum: formatRoku }} {{ 'paticka.spolecnost' | lokalizace }}</p>
			<p class="gov-footnote">{{ 'paticka.verze' | lokalizace }} {{ verzeCislo }}</p>
		</section>

		<button class="gov-button gov-button--primary-outlined gov-button--inversed gov-footer__scroll-up gov-js--scroll-up" aria-labelledby="fo-scrollBtn"
				title="{{ 'paticka.tlacitko.zpetNahoru' | lokalizace }}" (click)="odskrolovatNaZacatekStranky()">
			<span id="fo-scrollBtn" class="u-sr-only gov-button__label">{{ 'paticka.tlacitko.zpetNahoru' | lokalizace }}</span>
		</button>
	</div>
</footer>
