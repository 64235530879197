export enum DataType {
	STRING = 'STRING',
	MULTIPLE_VALUES_STRING = 'MULTIPLE_VALUES_STRING',
	MULTIPLE_NUMBER_VALUES_STRING = 'MULTIPLE_NUMBER_VALUES_STRING',
	NUMBER = 'NUMBER',
	DATE = 'DATE',
	ENUM = 'ENUM',
	BOOLEAN = 'BOOLEAN',
	FILE_SIZE = 'FILE_SIZE'
}
