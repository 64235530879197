import {prostredi} from '../../../prostredi/prostredi';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeLokalnihoUloziste {

	private static readonly oddelovacPrefixuKliceLokalnihoUloziste = '_';

	public static pripravitHodnotu(klic: string): string {
		return localStorage.getItem(this.pripravitKlicLokalnihoUloziste(klic));
	}

	public static vytvoritHodnotu(klic: string, hodnota: string): void {
		localStorage.setItem(this.pripravitKlicLokalnihoUloziste(klic), hodnota);
	}

	public static smazatHodnotu(klic: string): void {
		localStorage.removeItem(this.pripravitKlicLokalnihoUloziste(klic));
	}

	private static pripravitKlicLokalnihoUloziste(klic: string): string {
		return NastrojeObecne.formatovatText('{0}{1}{2}', prostredi.verze, this.oddelovacPrefixuKliceLokalnihoUloziste, klic);
	}
}
