<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<mat-chip-list [maxlength]="maximalniPocetSouboru" [formControlName]="nazevPrvkuFormulare" [tabIndex]="tabIndex">
			<mat-chip *ngFor="let item of formController.value; let i=index"
					  (removed)="remove(i)"
					  [selectable]="false"
					  [matTooltip]="item | vstupniSouborNaObrazek">
				<div class="link" (click)="stahnout(item)">{{ item.nazev }} ({{ item.velikost | velikostSouboru }})</div>
				<mat-icon matChipRemove>cancel</mat-icon>
			</mat-chip>
			<input #fileInput class="input-field__input" type="file" (change)="pridatVsechny($event)"
				   [required]="jeHodnotaPovinna()"
				   [multiple]="viceSouboru"
				   [accept]="povoleneSoubory">
		</mat-chip-list>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
		<app-ikona-kontextove-napovedy *ngIf="identifikatorPoleProKontextovouNapovedu"
									   [identifikatorPoleProKontextovouNapovedu]="identifikatorPoleProKontextovouNapovedu"></app-ikona-kontextove-napovedy>
	</mat-form-field>

	<div class="controls">
		<button #addButton class="mat-button btn-secondary-white" mat-stroked-button title="{{ 'formular.soubor.pridatSoubor' | lokalizace }}" (keydown.enter)="fileInput.click()">
			<mat-icon (click)="fileInput.click()">add</mat-icon>
		</button>

		<button *ngIf="!jePrazdny()" class="mat-button btn-secondary-white" mat-stroked-button title="{{ 'formular.soubor.vymazat' | lokalizace }}"
				(keydown.enter)="odstranitVsechny()"
				(focus)="vypnoutChipListFocus()"
				(blur)="zobrazitChipListFocus()">
			<mat-icon (click)="odstranitVsechny()">delete</mat-icon>
		</button>
	</div>
</div>
