import {Component, OnInit} from '@angular/core';
import {prostredi} from '../../../prostredi/prostredi';
import {NastrojeUrl} from '../../shared/nastroje/nastroje-url';

@Component({
	selector: 'app-home',
	templateUrl: './domu.component.html',
	styleUrls: ['./domu.component.scss']
})
export class DomuComponent implements OnInit {

	public readonly urlAplikace = prostredi.url;

	private readonly urlVerejneAplikace = prostredi.urlVerejneAplikace;
	private readonly verzeAplikace = prostredi.verze;

	constructor() {
	}

	ngOnInit(): void {
		if (this.verzeAplikace !== 'Lokalni') {
			this.presmerovatNaVerejnouAplikaci();
		}
	}

	private presmerovatNaVerejnouAplikaci(): void {
		NastrojeUrl.otevritUrlVeStejneZalozce(this.urlVerejneAplikace);
	}
}
