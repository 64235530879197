<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input matInput type="text" placeholder="{{ placeholder | lokalizace }}" autocomplete="off"
			   [formControlName]="nazevPrvkuFormulare"
			   [disabled]="vypnoutPrvekFormulare()"
			   [required]="jeHodnotaPovinna()"
			   (blur)="formatovatVstupniHodnotu()">
		<ngx-material-timepicker-toggle matSuffix [for]="picker"></ngx-material-timepicker-toggle>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
	<input type="hidden"
		   [ngxTimepicker]="picker"
		   [format]="format">
	<ngx-material-timepicker #picker
							 [ESC]="!vypnoutZavreni"
							 [cancelBtnTmpl]="tlacitkoZavrit"
							 [confirmBtnTmpl]="tlacitkoUlozit"
							 [preventOverlayClick]="vypnoutZavreni"
							 (timeSet)="zmenitHodnotuPrvkuFormulare($event)"></ngx-material-timepicker>
</div>

<ng-template #tlacitkoZavrit>
	<button mat-raised-button>{{ 'tlacitkoZavrit' | lokalizace}}</button>
</ng-template>
<ng-template #tlacitkoUlozit>
	<button mat-raised-button color="primary">{{ 'tlacitkoUlozit' | lokalizace}}</button>
</ng-template>
