import {Injectable} from '@angular/core';
import {NativeDateAdapter} from '@angular/material/core';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';
import {NastrojeDataACasu} from '../../../../nastroje/nastroje-data-a-casu';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

	parse(value: any): Date | null {
		return NastrojeObecne.jePrazdnaHodnota(value) ? null : NastrojeDataACasu.prevestTextNaDatum(value, NastrojeDataACasu.FORMAT_DATA);
	}

	getFirstDayOfWeek(): number {
		return 1;
	}
}
