import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {AutentizaceService} from './autentizace.service';

@Injectable()
export class AuthGuardService extends KeycloakAuthGuard {

	constructor(
		protected override router: Router,
		protected keycloakService: KeycloakService,
		private autentizaceService: AutentizaceService
	) {
		super(router, keycloakService);
	}

	public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		const kontrolovatOpravneni = route.data['kontrolovatOpravneni'];

		await this.zkontrolovatProfilInstanceKeycloaku(kontrolovatOpravneni);
		await this.zkontrolovatPrihlaseni(kontrolovatOpravneni);

		return !kontrolovatOpravneni || this.authenticated;
	}

	private async zkontrolovatProfilInstanceKeycloaku(kontrolovatOpravneni: boolean): Promise<void> {
		if (!this.authenticated && this.keycloakService.getKeycloakInstance().profile !== undefined) {
			if (kontrolovatOpravneni) {
				await this.autentizaceService.prihlasit();
			} else {
				await this.autentizaceService.odhlasit();
			}
		}
	}

	private async zkontrolovatPrihlaseni(kontrolovatOpravneni: boolean): Promise<void> {
		if (kontrolovatOpravneni && !this.authenticated) {
			await this.autentizaceService.prihlasit();
		}
	}
}

