import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl, ValidatorFn} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';
import {UpozorneniService} from '../../../../../service/upozorneni.service';
import {TypUpozorneni} from '../../../../../data/general/enum/typ-upozorneni.enum';
import {NastrojeValidace} from '../../../../nastroje/nastroje-validace';

@Component({
	selector: 'app-formular-chips',
	templateUrl: './formular-chips.component.html',
	styleUrls: ['./formular-chips.component.scss']
})
export class FormularChipsComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Array of input strings
	 */
	@Input() polozky: Array<string>;

	/**
	 * Defines input validatory
	 */
	@Input() validatory: ValidatorFn[];

	public readonly kodyOddelovacu: number[] = [ENTER, COMMA];

	constructor(private notificationService: UpozorneniService) {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.aktualizovatChipsData();
	}

	public pridat(event: MatChipInputEvent): void {
		const value = event.value.trim();
		const temporaryFormControl = new UntypedFormControl(value, this.validatory);

		if (temporaryFormControl.valid) {
			this.polozky.push(value);
			event.input.value = '';
			this.aktualizovatChipsData();
		} else {
			this.notificationService.upozornit(TypUpozorneni.CHYBA, NastrojeValidace.pripravitTextErrorZpravy(temporaryFormControl, this.napovedaSChybou, this.napoveda));
		}
	}

	public odebrat(index: number): void {
		this.polozky.splice(index, 1);
		this.aktualizovatChipsData();
	}

	private aktualizovatChipsData(): void {
		this.formController.setValue(this.polozky);
	}
}
