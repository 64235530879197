import {Component, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-textove-pole',
	templateUrl: './formular-textove-pole.component.html',
	styleUrls: ['./formular-textove-pole.component.scss']
})
export class FormularTextovePoleComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
