export const prostredi = {
	produkcni: true,

	verze: 'Vyvojova',
	verzeCislo: '2.1.2',
	korenovaUrl: '/',

	url: 'https://dev.cdz.novyvvz.com',
	urlVerejneAplikace: 'https://dev.novyvvz.com',

	keycloakUrl: 'https://dev.novyvvz.com/auth',
	realm: 'VVZ',
	clientId: 'vvz-frontend',

	hlavickyBackend: {
		'accept': 'application/json',
		'Content-Type': 'application/json',
	},
	hlavickyBackendStahovani: {
		'accept': 'application/octet-stream',
	},

	//Swagger
	urlSwagger: 'https://virtserver.swaggerhub.com/ondrej.hladik/test/1.0.0',

	// Mikrosluzby
	urlAntivirovaKontrolaSouboru: 'https://dev.api.novyvvz.com/vvz-antivirova-kontrola',
	urlSpravaSubjektuAUzivatelu: 'https://dev.api.novyvvz.com/vvz-sprava-subjektu-a-uzivatelu',
	urlKontextovaNapoveda: 'https://dev.api.novyvvz.com/vvz-kontextova-napoveda/napoveda',
	urlNotifikaceDodavatelu: 'https://dev.api.novyvvz.com/vvz-notifikace-dodavatelu',
	urlNia: 'https://dev.api.novyvvz.com/vvz-nia',
	urlIen: 'https://dev.api.novyvvz.com/vvz-ien'
};
