import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';
import {NastrojeObecne} from '../../../../nastroje/nastroje-obecne';

@Component({
	selector: 'app-formular-wysiwyg-editor',
	templateUrl: './formular-wysiwyg-editor.component.html',
	styleUrls: ['./formular-wysiwyg-editor.component.scss']
})
export class FormularWysiwygEditorComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	@Input() readonly: boolean = false;
	@Input() vlastniVyska: string;

	public readonly defaultniKonfigurace = {
		toolbar: [
			[{'size': ['small', false, 'large', 'huge']}],
			['bold', 'italic', 'underline'],
			[{'align': [] as any}], 	//TODO FIX
			[{'header': [1, 2, 3, false]}],
			['blockquote'],
			[{'list': 'ordered'}, {'list': 'bullet'}],
			[{'color': [] as any}, {'background': [] as any}],
			['link', 'image'],
			['clean']
		]
	};

	public readonly readonlyKonfigurace = {
		toolbar: false
	};

	public editorStyly = {
		height: '500px'
	};

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.nastavitVlastniVyskuEditoru();
	}

	public nastavitVlastniNastaveniEditoru(editor: any): void {
		editor.root.setAttribute('spellcheck', false);
	}

	private nastavitVlastniVyskuEditoru(): void {
		if (!NastrojeObecne.jePrazdnaHodnota(this.vlastniVyska)) {
			this.editorStyly.height = this.vlastniVyska;
		}
	}
}
