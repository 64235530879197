import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormControl, Validators} from '@angular/forms';
import {NastrojeValidace} from '../../shared/nastroje/nastroje-validace';
import {AbstraktniFormularComponent} from '../../shared/form/abstraktni-formular.component';
import {UpozorneniService} from '../../service/upozorneni.service';
import {NotifikaceDodavateluService} from '../../service/notifikace-dodavatelu.service';
import {NastrojeLokalizace} from '../../shared/nastroje/nastroje-lokalizace';
import {TypLokalizace} from '../../data/general/enum/typ-lokalizace.enum';
import {TypUpozorneni} from '../../data/general/enum/typ-upozorneni.enum';

@Component({
	selector: 'app-modal-odebirat-upozorneni',
	templateUrl: './modal-odebirat-upozorneni.component.html',
	styleUrls: ['./modal-odebirat-upozorneni.component.scss']
})
export class ModalOdebiratUpozorneniComponent extends AbstraktniFormularComponent implements OnInit {

	constructor(
		protected formBuilder: UntypedFormBuilder,
		protected upozorneniService: UpozorneniService,
		private notifikaceDodavateluService: NotifikaceDodavateluService,
		private dialogRef: MatDialogRef<ModalOdebiratUpozorneniComponent>
	) {
		super(upozorneniService);
	}

	ngOnInit(): void {
		this.inicializovatFormular();
		this.inicializovatPrvkyFormulare();
	}

	public send(): void {
		const lokalizace = NastrojeLokalizace.pripravitLokalizaci() === TypLokalizace.CZ ? 'CZ' : 'EN';

		this.notifikaceDodavateluService.prihlasitSeKOdberuUpozorneni(this.formGroup.controls['emailovaAdresa'].value, lokalizace)
			.then(() => this.upozorneniService.upozornit(TypUpozorneni.USPECH, 'modalOdebiratUpozorneni.zadostUspesneOdeslana'));
	}

	public zavrit(): void {
		this.dialogRef.close();
	}

	private inicializovatPrvkyFormulare(): void {
		this.formGroup.addControl('emailovaAdresa', new UntypedFormControl('', [
			Validators.required,
			Validators.pattern(NastrojeValidace.VZOR_EMAIL)
		]));
	}
}
