import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {TypUpozorneni} from '../data/general/enum/typ-upozorneni.enum';
import {NastrojeJsonu} from '../shared/nastroje/nastroje-jsonu';
import {NastrojeUpozorneni} from '../shared/nastroje/nastroje-upozorneni';
import {Lokalizace} from '../shared/lokalizace';

@Injectable()
export class UpozorneniService {

	private readonly klicTlacitkaNaZavreni = 'characterEx';
	private readonly minimalniDobaZobrazeni = 3000;
	private readonly maximalniDobaDobrazeni = 30000;
	private readonly dobaZobrazeniProJedenZnak = 80;
	private readonly oddelovacViceHodnot = '\n';

	private readonly vychoziKonfiguraceUpozorneni: MatSnackBarConfig = {
		horizontalPosition: 'center' as MatSnackBarHorizontalPosition,
		verticalPosition: 'top' as MatSnackBarVerticalPosition
	};

	constructor(private upozorneni: MatSnackBar) {
	}

	public upozornit(typ: TypUpozorneni, zprava: string, ...parametry: string[]): void {
		this.vychoziKonfiguraceUpozorneni.panelClass = NastrojeUpozorneni.pripravitTypUpozorneni(typ);
		this.vychoziKonfiguraceUpozorneni.duration = this.pripravDobuZobrazeni(zprava, parametry);

		this.upozorneni.open(Lokalizace.formatovatText(zprava, parametry), Lokalizace.pripravitText(this.klicTlacitkaNaZavreni), this.vychoziKonfiguraceUpozorneni);
	}

	public upozornitVicenasobne(typ: TypUpozorneni, zprava: {klic: string, parametry: string[]}[], lokalizovatParametry = false): void {
		const finalMessage = zprava.map(message => Lokalizace.formatovatText(message.klic, ...this.pripravitParametry(message.parametry, lokalizovatParametry))).join(this.oddelovacViceHodnot);

		this.vychoziKonfiguraceUpozorneni.panelClass = NastrojeUpozorneni.pripravitTypUpozorneni(typ);
		this.vychoziKonfiguraceUpozorneni.duration = this.pripravDobuZobrazeni(finalMessage);

		this.upozorneni.open(finalMessage, Lokalizace.pripravitText(this.klicTlacitkaNaZavreni), this.vychoziKonfiguraceUpozorneni);
	}

	public upozornitDorucenouJsonChybou(chyba: any): void {
		(chyba.error as Blob).text().then((error: string) => {
			const chybaJson = NastrojeJsonu.parsovat(error);

			this.upozornit(TypUpozorneni.CHYBA, chybaJson.klic, chybaJson.parametry);
		});
	}

	private pripravitParametry(parametry: any[], lokalizovatParametry: boolean): any[] {
		return parametry ? (lokalizovatParametry ? parametry.map(parameter => Lokalizace.pripravitText(Lokalizace.prevestKlicNaLowerCamelCase(parameter))) : parametry) : [];
	}

	private pripravDobuZobrazeni(zprava: string, ...parametry: any[]): number {
		return Math.min(Math.max(Lokalizace.formatovatText(zprava, parametry).length * this.dobaZobrazeniProJedenZnak, this.minimalniDobaZobrazeni), this.maximalniDobaDobrazeni);
	}
}
