<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<mat-select [formControlName]="nazevPrvkuFormulare" [required]="jeHodnotaPovinna()" [attr.disabled]="vypnoutPrvekFormulare()">
			<mat-option>{{ nezvolenyPopisek | lokalizace }}</mat-option>
			<mat-option *ngFor="let item of polozky | klice | selectSort: seraditPolozky: polozky" [value]="item.value | typEnumu: polozky">{{ item.value | typEnumu: polozky }}</mat-option>
		</mat-select>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
