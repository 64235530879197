import {TypUpozorneni} from '../../data/general/enum/typ-upozorneni.enum';
import {UpozorneniService} from '../../service/upozorneni.service';

export class NastrojePozadavku {

	public static readonly httpStatusUnprocessableEntity = 422;

	public static zpracovatChybu(upozorneniService: UpozorneniService, chyba: Response | any): Promise<any> {
		const vychoziKlicTextuChyby = 'chyba.vychoziChybovaHlaska';

		if (chyba.error instanceof Array) {
			upozorneniService.upozornitVicenasobne(TypUpozorneni.CHYBA, chyba.error || vychoziKlicTextuChyby);
		} else {
			upozorneniService.upozornit(TypUpozorneni.CHYBA, (chyba.error && chyba.error.klic) || vychoziKlicTextuChyby);
		}

		return Promise.reject(chyba.message || chyba);
	}
}
