import {Component, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-heslo',
	templateUrl: './formular-heslo.component.html',
	styleUrls: ['./formular-heslo.component.scss']
})
export class FormularHesloComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	public ukazHeslo: boolean = false;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}

	public prepnoutViditelnostHesla(): void {
		this.ukazHeslo = !this.ukazHeslo;
	}
}
