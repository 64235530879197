import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-nadpis',
	templateUrl: './nadpis.component.html',
	styleUrls: ['./nadpis.component.scss']
})
export class NadpisComponent {

	@Input() klicTextu: string;
}
