import {Pipe, PipeTransform} from '@angular/core';
import {KonfiguracePole} from '../../data/table/table-configuration/konfigurace-pole';

@Pipe({
	name: 'poleFiltruTabulky'
})
export class PoleFiltruTabulkyPipe implements PipeTransform {

	transform(konfiguracePoliFiltru: KonfiguracePole[], indexZalozkyFiltru: number): KonfiguracePole[] {
		return indexZalozkyFiltru === -1 ? konfiguracePoliFiltru : konfiguracePoliFiltru.filter((konfiguracePole) => konfiguracePole.zalozka === indexZalozkyFiltru);
	}
}
