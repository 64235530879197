import {TypUpozorneni} from '../../data/general/enum/typ-upozorneni.enum';

export class NastrojeUpozorneni {

	public static pripravitTypUpozorneni(typUpozorneni: TypUpozorneni): string {
		switch (+typUpozorneni) {
			case TypUpozorneni.USPECH:
				return 'notification-success';
			case TypUpozorneni.INFORMACE:
				return 'notification-information';
			case TypUpozorneni.VAROVANI:
				return 'notification-warning';
			case TypUpozorneni.CHYBA:
				return 'notification-error';
			default:
				return '';
		}
	}
}
