import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable()
export class FormularMultipleAutocompleteService {

	@Output() optionSelectedEvent = new EventEmitter<{guid: string, nazevAtributu: string, data: any}>();

	public emitOptionSelectedEvent(guid: string, nazevAtributu: string, data: any): void {
		this.optionSelectedEvent.emit({guid, nazevAtributu, data});
	}
}
