<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input matInput type="{{ typ }}"
			   placeholder="{{ placeholder | lokalizace }}" autocomplete="off"
			   [formControlName]="nazevPrvkuFormulare"
			   [attr.disabled]="vypnoutPrvekFormulare()"
			   [required]="jeHodnotaPovinna()"
			   (blur)="formatovatVstupniHodnotu()">

		<app-ikona-kontextove-napovedy *ngIf="identifikatorPoleProKontextovouNapovedu"
									   [identifikatorPoleProKontextovouNapovedu]="identifikatorPoleProKontextovouNapovedu"></app-ikona-kontextove-napovedy>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
