import {Pipe, PipeTransform} from '@angular/core';
import {Lokalizace} from '../lokalizace';

@Pipe({
	name: 'lokalizace'
})
export class LokalizacePipe implements PipeTransform {

	transform(klic: any, formatovatText = false, ...parametry: any[]): any {
		return formatovatText ? Lokalizace.formatovatText(klic, ...parametry) : Lokalizace.pripravitText(klic);
	}
}
