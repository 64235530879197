import {Injectable} from '@angular/core';

@Injectable()
export class ScrollService {

	constructor() {
	}

	public registrovatScroll(): void {
		document.addEventListener('scroll', this.storeScroll);
		document.addEventListener('touchmove', this.storeScroll);
		this.storeScroll();
	}

	private storeScroll(): void {
		if (document.documentElement.className == 'cdk-global-scrollblock') {
			document.documentElement.dataset['scroll'] = document.documentElement.style.top.slice(1, document.documentElement.style.top.length - 2);
		} else {
			document.documentElement.dataset['scroll'] = `${window.pageYOffset}`;
		}
	}
}
