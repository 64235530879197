import {Injectable} from '@angular/core';
import {Router, RoutesRecognized} from '@angular/router';
import {filter, pairwise} from 'rxjs/operators';

@Injectable()
export class RouteInterceptorService {

	public aktualniStranka: string;
	public predchoziStranka: string;

	constructor(router: Router) {
		router.events
			.pipe(filter((e: any) => e instanceof RoutesRecognized), pairwise())
			.subscribe((e: any) => {
				this.predchoziStranka = e[0].urlAfterRedirects;
				this.aktualniStranka = e[1].url;
			});
	}

	public jeNadrazenaStranka(): boolean {
		return this.aktualniStranka !== this.predchoziStranka && this.predchoziStranka.includes(this.aktualniStranka);
	}
}
