import {NastrojeJsonu} from './nastroje-jsonu';

export class NastrojeObecne {

	public static jePrazdnaHodnota(hodnota: any): boolean {
		return hodnota === null || hodnota === undefined || hodnota === '';
	}

	public static jePrazdnaHodnotaNeboPrazdnePole(hodnota: any): boolean {
		return this.jePrazdnaHodnota(hodnota) || (Array.isArray(hodnota) && hodnota.length === 0);
	}

	public static jeHodnotaRetezec(hodnota: any): boolean {
		return typeof hodnota === 'string' || hodnota instanceof String;
	}

	public static formatovatText(text: string, ...parametry: any[]): string {
		return text.replace(/{(\d+)}/g, function(polozka, index) {
			return typeof parametry[index] !== 'undefined' ? parametry[index] :
				polozka;
		});
	}

	public static vygenerovatNahodnyRetezec(pozadovanaDelka = 32): string {
		const povoleneZnaky: string = 'abcdefghijklmnopqrstuvwxyz'; // Pouze pismena, jelikoz prvni znak hodnoty html atributu nemuze byt cislo
		let vysledek: string = '';

		for (let i = 0; i < pozadovanaDelka; i++) {
			vysledek += povoleneZnaky.charAt(Math.floor(Math.random() * povoleneZnaky.length));
		}

		return vysledek;
	}

	public static vytvoritHlubokouKopii<T>(data: T): T {
		return NastrojeJsonu.parsovat(NastrojeJsonu.prevestNaText(data));
	}
}
