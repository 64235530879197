import {TypLokalizace} from '../../data/general/enum/typ-lokalizace.enum';
import {ZpusobDolozeniOpravneni} from '../../data/registrace/enum/zpusob-dolozeni-opravneni.enum';
import {Role} from '../../data/sdilene/enum/role.enum';
import {StavUzivatele} from '../../data/sdilene/enum/stav-uzivatele.enum';
import {TypSubjektu} from '../../data/sdilene/enum/typ-subjektu';
import {DuvodZruseniUzivatele} from '../../data/sprava-administratoru-a-uzivatelu/enum/duvod-zruseni-uzivatele.enum';
import {Colors} from '../../data/testing-page/enum/colors.enum';
import {FavouriteColor} from '../../data/testing-page/enum/favourite-color.enum';
import {NastrojeObecne} from './nastroje-obecne';
import {NastrojeRetezcu} from './nastroje-retezcu';
import {TypDoplnkuKUrednimuNazvu} from '../../data/registrace/enum/typ-doplnku-k-urednimu-nazvu.enum';

export class NastrojeEnumu {

	private static readonly enumy = {
		Colors,
		DuvodZruseniUzivatele,
		FavouriteColor,
		Role,
		StavUzivatele,
		TypLokalizace,
		TypSubjektu,
		ZpusobDolozeniOpravneni,
		TypDoplnkuKUrednimuNazvu
	};

	public static pripravitTriduEnumuJakoText(tridaEnumu: any): string {
		for (const item of Object.entries(this.enumy)) {
			if (tridaEnumu === item[1]) {
				return item[0];
			}
		}

		return '';
	}

	public static pripravitKlicHodnotyEnumuProLokalizaci(nazevTridyEnumu: string, hodnotaEnumu: string): string {
		if (NastrojeObecne.jePrazdnaHodnota(nazevTridyEnumu) || NastrojeObecne.jePrazdnaHodnota(hodnotaEnumu)) {
			return '';
		}

		const nazevTridyEnumuProLokalizaci = NastrojeRetezcu.prevestUpperCamelCaseNaLowerCamelCase(nazevTridyEnumu);
		const nazevHodnotyEnumuProLokalizaci = NastrojeRetezcu.prevestUpperSnakeCaseNaLowerCamelCase(hodnotaEnumu);

		return NastrojeObecne.formatovatText('{0}.{1}', nazevTridyEnumuProLokalizaci, nazevHodnotyEnumuProLokalizaci);
	}

	public static pripravitEnumDleNazvuTridy(nazevTridyEnumu: string): any {
		for (const item of Object.entries(this.enumy)) {
			if (nazevTridyEnumu === item[0]) {
				return item[1];
			}
		}

		return {};
	}
}
