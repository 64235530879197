<div [formGroup]="parentFormGroup">
	<mat-form-field class="input-field" appearance="outline">
		<mat-label>{{ popisek | lokalizace }}</mat-label>
		<input #inputAutocomplete type="text" matInput
			   [formControl]="formController"
			   [matAutocomplete]="auto"
			   [attr.disabled]="vypnoutPrvekFormulare()"
			   [required]="jeHodnotaPovinna()">
		<mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption [panelWidth]="'auto'">
			<mat-option class="multiple-autocomplete" *ngFor="let item of vyfiltrovanePolozky | async" [value]="item[nazevDataAtributu]" (onSelectionChange)="emitOptionSelectedEvent($event, item)">
				<span class="multiple-option-wrapper" [style.grid-template-columns]="gridTemplateColumns">
					<span *ngFor="let key of nazvyDataAtributu"
						  [class.secondary-option-value]="key !== nazevDataAtributu"
						  [innerHTML]="key === nazevDataAtributu ? (pripravHodnotuAtributu(item, key) | zvyraznit: formController.value) : pripravHodnotuAtributu(item, key)"></span>
				</span>
			</mat-option>
		</mat-autocomplete>
		<mat-hint>{{ napoveda | lokalizace }}</mat-hint>
		<mat-error>{{ pripravitErrorZpravu() }}</mat-error>
	</mat-form-field>
</div>
