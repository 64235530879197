import Keycloak from 'keycloak-js';
import {NastrojeJsonu} from './nastroje-jsonu';
import {NastrojeLokalnihoUloziste} from './nastroje-lokalniho-uloziste';

export class NastrojeAutentizace {

	private static readonly klicInformaciOUzivateliVLokalnimUlozisti = 'informaceOUzivateli';

	public static ulozitInformaceOUzivateliDoLokalnihoUloziste(informaceOUzivateli: Keycloak.KeycloakProfile): void {
		NastrojeLokalnihoUloziste.vytvoritHodnotu(this.klicInformaciOUzivateliVLokalnimUlozisti, NastrojeJsonu.prevestNaText(informaceOUzivateli));
	}

	public static pripravitInformaceOUzivateliZLokalnihoUloziste(): Keycloak.KeycloakProfile {
		return NastrojeJsonu.parsovat(NastrojeLokalnihoUloziste.pripravitHodnotu(this.klicInformaciOUzivateliVLokalnimUlozisti));
	}

	public static odstranitInformaceOUzivateliZLokalnihoUloziste(): void {
		NastrojeLokalnihoUloziste.smazatHodnotu(this.klicInformaciOUzivateliVLokalnimUlozisti);
	}

	public static pripravitNastaveniKeycloaku(url: string, realm: string, clientId: string): any {
		return {
			initOptions: {
				onLoad: 'check-sso',
				checkLoginIframe: false,
			},
			config: {
				url: url,
				realm: realm,
				clientId: clientId,
			},
			enableBearerInterceptor: true,
			loadUserProfileAtStartUp: true
		};
	}
}
