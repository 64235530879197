import {prostredi} from '../../../prostredi/prostredi';
import {KeycloakService} from 'keycloak-angular';
import {NastrojeAutentizace} from '../../shared/nastroje/nastroje-autentizace';

export function initializer(keycloakService: KeycloakService): () => Promise<any> {
	return (): Promise<any> => {
		return new Promise(async(resolve, reject) => {
			const nastaveniKeycloaku = NastrojeAutentizace.pripravitNastaveniKeycloaku(prostredi.keycloakUrl, prostredi.realm, prostredi.clientId);
			try {
				 await keycloakService.init(nastaveniKeycloaku);

				resolve(true);
			} catch (error) {
				// TODO Pokud vyprsi token, vraci metoda init v nekterych pripadech prazdnou hodnotu.
				if (error === undefined) {
					try {
						await keycloakService.init(nastaveniKeycloaku);

						resolve(true);
					} catch (error) {
						reject(error);
					}
				}
			}
		});
	};
}
