import {Component, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-prepinac',
	templateUrl: './formular-prepinac.component.html',
	styleUrls: ['./formular-prepinac.component.scss']
})
export class FormularPrepinacComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
