import {Component, Input, OnInit} from '@angular/core';
import {AbstraktniFormularovyPrvekComponent} from '../abstraktni-formularovy-prvek.component';

@Component({
	selector: 'app-formular-select',
	templateUrl: './formular-select.component.html',
	styleUrls: ['./formular-select.component.scss']
})
export class FormularSelectComponent extends AbstraktniFormularovyPrvekComponent implements OnInit {

	/**
	 * Array of input items
	 */
	@Input() polozky: any;

	/**
	 * Defines if items will be sorted
	 */
	@Input() seraditPolozky = true;

	/**
	 * Defines not selected label
	 */
	@Input() nezvolenyPopisek = 'formularSelect.nezvoleno';

	/**
	 * Defines if source of data is object (default source is enum)
	 */
	@Input() dataFromObject = false;

	/**
	 * Defines name of objects key attribute
	 */
	@Input() objectKeyName = 'key';

	/**
	 * Defines name of objects value attribute
	 */
	@Input() objectValueName = 'value';

	/**
	 * Definuje identifikator pre kontextovou napovedu
	 */
	@Input() identifikatorPoleProKontextovouNapovedu: string;

	constructor() {
		super();
	}

	ngOnInit(): void {
		super.ngOnInit();
	}
}
