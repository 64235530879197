import {KonfiguraceFormatuSloupce} from '../../data/table/table-configuration/konfigurace-formatu-sloupce';
import {DataType} from '../enum/data-type.enum';
import {NastrojeRetezcu} from './nastroje-retezcu';
import {NastrojeCisel} from './nastroje-cisel';
import {NastrojeDataACasu} from './nastroje-data-a-casu';
import {Lokalizace} from '../lokalizace';
import {NastrojeEnumu} from './nastroje-enumu';
import {NastrojeObecneSoubory} from './nastroje-obecne-soubory';
import {NastrojeObecne} from './nastroje-obecne';

export class NastrojeFormatovani {

	private static readonly oddelovaceViceHodnot = /;/g;
	private static readonly nahrazovacOddelovaceViceHodnot = '\n';

	public static formatovat(hodnota: any, format: KonfiguraceFormatuSloupce = {typ: DataType.STRING}): any {
		switch (format.typ) {
			case DataType.STRING:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : hodnota;
			case DataType.MULTIPLE_NUMBER_VALUES_STRING:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : hodnota.split(this.oddelovaceViceHodnot).map(v => NastrojeCisel.formatovatCislo(v)).join(this.nahrazovacOddelovaceViceHodnot);
			case DataType.MULTIPLE_VALUES_STRING:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : hodnota.replace(this.oddelovaceViceHodnot, this.nahrazovacOddelovaceViceHodnot);
			case DataType.NUMBER:
				return isNaN(hodnota) ? '' : NastrojeCisel.formatovatCislo(hodnota, format.zobrazitOddelovacTisicu, format.zaokrouhleni);
			case DataType.DATE:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : NastrojeDataACasu.formatovatDatum(hodnota, format.formatDatumu.toString());
			case DataType.ENUM:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : Lokalizace.pripravitText(NastrojeEnumu.pripravitKlicHodnotyEnumuProLokalizaci(format.nazevEnumu, hodnota));
			case DataType.BOOLEAN:
				return NastrojeRetezcu.jeRetezecNullNeboUndefined(hodnota) ? '' : Lokalizace.pripravitText(hodnota);
			case DataType.FILE_SIZE:
				return NastrojeObecne.jePrazdnaHodnota(hodnota) ? '' : NastrojeObecneSoubory.pripravitVelikostSouboruJakoText(hodnota);
			default:
				return hodnota;
		}
	}
}
