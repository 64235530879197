import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LokalizacePipe} from './lokalizace.pipe';
import {VstupniSouborNaObrazekPipe} from './vstupni-soubor-na-obrazek.pipe';
import {VelikostSouboruPipe} from './velikost-souboru.pipe';
import {ZvyraznitPipe} from './zvyraznit.pipe';
import {KlicePipe} from './klice.pipe';
import {SelectSortPipe} from './select-sort.pipe';
import {TypEnumuPipe} from './typ-enumu.pipe';
import {FormatovatPipe} from './formatovat.pipe';
import {DatumPipe} from './datum.pipe';
import {EnumDleNazvuPipe} from './enum-dle-nazvu.pipe';
import {PoleFiltruTabulkyPipe} from './pole-filtru-tabulky.pipe';

@NgModule({
	declarations: [
		LokalizacePipe,
		VstupniSouborNaObrazekPipe,
		VelikostSouboruPipe,
		ZvyraznitPipe,
		KlicePipe,
		SelectSortPipe,
		TypEnumuPipe,
		FormatovatPipe,
		DatumPipe,
		EnumDleNazvuPipe,
		PoleFiltruTabulkyPipe
	],
	imports: [
		CommonModule
	],
	exports: [
		LokalizacePipe,
		VstupniSouborNaObrazekPipe,
		VelikostSouboruPipe,
		ZvyraznitPipe,
		KlicePipe,
		SelectSortPipe,
		TypEnumuPipe,
		FormatovatPipe,
		DatumPipe,
		EnumDleNazvuPipe,
		PoleFiltruTabulkyPipe
	]
})
export class PipesModule {
}
